// External Libraries and Packages
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
// Internal Styles
// Internal Components
import Error404 from "../../Error404";
import PageLoader from "../../Loader/PageLoader";
import { viewAllPageAction } from "../../../Redux/Actions/apiActions";
import PageError from "../../PageError";
import LayoutViewAll from "./LayoutViewAll";

const ViewAllPage = ({ settings, siteTheme, isLoading, error, viewAllData }) => {
  const { source } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const baseURL = `${process.env.REACT_APP_Baseurl}/Front-End`

  const apiCollect = Object.fromEntries([
    ["latest-videos", "/Latest-videos-Page-List"],
    ["featured-videos", "/Featured-videos-Page-List"],
    ["scheduled-publish-video", "/Scheduled-Publish-Video-Page-List"],
    ["video-schedule", "/Video-Schedule-Page-List"],
    ["latest-viewed-videos", "/Latest-viewed-videos-Page-List"],
    ["recommended-videos-site", "/Recommended-videos-site-Page-List"],
    ["recommended-videos-country", "/Recommended-videos-country-Page-List"],
    ["recommended-videos-users", "/Recommended-videos-users-Page-List"],
    ["continue-watching", "/continue-watching-list"],
    ["latest-series", "/Latest-Series-Page-List"],
    ["single-series", "/single-series-Page-List"],
    ["latest-viewed-episode", "/Latest-viewed-Episode-Page-List"],
    ["livestream-videos", "/Livestream-videos-Page-List"],
    ["scheduled-publish-livestream", "/Scheduled-Publish-LiveStream-Page-List"],
    ["latest-viewed-audios", "/Latest-viewed-audios-Page-List"],
    ["audios", "/Audios-Page-List"],
    ["audio-albums", "/Audio-Albums-Page-List"],
    ["artists", "/Artists-Page-List"],
    ["channel-partner", "/Channel-Partner-Page-List"],
    ["individual-channelpartner", "/Individual-ChannelPartner-Page-List"],
    ["content-partner", "/Content-Partner-Page-List"],
    ["individual-contentpartner", "/Individual-ContentPartner-Page-List"],
    ["live-categories", "/LiveCategories-Page-List"],
    ["series-categories", "/Series-Genre-Page-List"],
    ["video-categories", "/videoCategories-Page-List"],
    ["audios-categories", "/Audio-Genre-Page-List"],
    ["alllanguage", "/Language-Page-List"]
  ].map(([key, value]) => [key, `${baseURL}${value}`]));

  const findPath = {
    "latest-videos": `videos`,
    "featured-videos": `videos`,
    "scheduled-publish-video": `videos`,
    "video-schedule": `videos`,
    "latest-viewed-videos": `videos`,
    "recommended-videos-site": `videos`,
    "recommended-videos-country": `videos`,
    "recommended-videos-users": `videos`,
    "continue-watching": `videos`,
    "latest-series": `shows`,
    "single-series": `shows`,
    "latest-viewed-episode": `shows`,
    "livestream-videos": `live`,
    "scheduled-publish-livestream": `live`,
    "latest-viewed-audios": `/music/track`,
    "audios": `/music/track`,
    "audio-albums": `/music/track`,
    "artists": `/music/artists`,
    "channel-partner": `channels`,
    "individual-channelpartner": `channels`,
    "content-partner": `partners`,
    "individual-contentpartner": `partners`,
    "live-categories": `live-categories`,
    "series-categories": `series-categories`,
    "video-categories": `video-categories`,
    "audios-categories": `audios-categories`,
    "alllanguage": `languages`,
  }
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      dispatch(viewAllPageAction(null, null, null, null, apiCollect[source]));
    }
  }, [settings, user_role, user_id, location, dispatch, source]);

  if (!apiCollect[source]) {
    return <Error404 />
  }

  return (
    <div className="paddingTop">
      {isLoading ? <PageLoader /> : error?.message?.length ? <PageError /> : <LayoutViewAll mapData={viewAllData?.data} source={source} path={findPath[source]} headingClass=' pb-4 text-center' />}
    </ div>
  );
};
const mapStateToProps = state => ({
  isLoading: state.get_viewall_Reducer.isLoading,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
  settings: state.get_setting_Reducer.settings,
  viewAllData: state.get_viewall_Reducer.viewAllData,
  error: state.get_viewall_Reducer.error,


});

export default connect(mapStateToProps)(ViewAllPage);