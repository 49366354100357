import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { videoDetailsAction } from "../../Redux/Actions/apiActions";
import { useNavigate } from "react-router-dom";
import VideosJs from "./VideosJs";
import { useTranslation } from "react-i18next";
import AudioVideoplayer from "./AudioVideoplayer";
import 'videojs-contrib-ads';
import 'videojs-ima';
import PageLoader from "../Loader/PageLoader";
const VideoPlayer = ({ videoDetailsData, isLoading, error, settings, slug, getWishListData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user_role = localStorage.getItem("role");
  const playerType = videoDetailsData?.type;
  const embedSource = videoDetailsData?.embed_code;
  const videoJsOptions = {
    controlBar: {
      audioTrackButton: false, subtitlesButton: false, remainingTimeDisplay: true,
      fullscreenToggle: false, volumePanel: { inline: false, vertical: true }, pictureInPictureToggle: true
      // volumePanel: false, 
    },
    bigPlayButton: false,
    controls: true,
    responsive: true,
    breakpoints: [{ tiny: 300, xsmall: 400, small: 500, medium: 600, large: 700, xlarge: 800, huge: 900 }],
    poster: videoDetailsData?.Player_thumbnail,
    textTrackSettings: false,
    fluid: true,
    muted: false,
    autoplay: true,
    sources: [{
      src: videoDetailsData?.videos_url,
      type: videoDetailsData?.video_player_type
    }],

    playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    html5: {
      vhs: {
        overrideNative: true
      }
    }
  };

  function loadGoogleIMASDK() {
    if (!window.google || !window.google.ima) {
        const script = document.createElement("script");
        script.src = "//imasdk.googleapis.com/js/sdkloader/ima3.js";
        script.async = true;
        script.onload = () => {
            console.log("IMA SDK Loaded");
        };
        script.onerror = () => console.error("Failed to load IMA SDK");
        document.body.appendChild(script);
    } else {
        console.log("IMA SDK already loaded");
    }
  }

  useEffect(() => {
    loadGoogleIMASDK();
    //dispatch(videoDetailsAction(slug, settings))
    dispatch(videoDetailsAction(slug, settings, true))
  }, [settings, user_role]);

  const playerRef = React.useRef(null);
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    console.log("player=>", player);
    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
  };
  const wishListCheck = getWishListData?.videos?.some(item => item.id === videoDetailsData.id);
  const advertisement = videoDetailsData?.get_advertisement;
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center paddingTop">
          <PageLoader />
        </div>
      ) : playerType == "embed_url|url" ? (
        <iframe src={embedSource} allowfullscreen width="1360" height="628" title="Video"></iframe>
      ) : videoDetailsData?.videos_url && videoDetailsData?.video_player_type
        ? <>
          <VideosJs options={videoJsOptions} onReady={handlePlayerReady} videoDetailsData={videoDetailsData}
            settings={settings} wishListCheckValue={wishListCheck} advertisement={advertisement}/>
        </>
        : <div className='d-flex justify-content-center align-items-center paddingTop'>
          <h3>{t("Invalid Video Source and Type - Try to reload the page")}</h3>
        </div>
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  error: state.get_videoDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  getWishListData: state?.get_wishList_Reducer?.getWishListData
});
export default connect(mapStateToProps)(VideoPlayer);