// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import "../../Styles/AllHomePage.css";

// Internal Components
import HomeVideoAll from "./HomeSwiperComponents/HomeVideoAll"
import LiveStreamCategoriesAll from "./HomeSwiperComponents/LiveStreamCategoriesAll";
import SeriesCategoriesAll from "./HomeSwiperComponents/SeriesCategoriesAll";
import AudioAlbumAll from "./HomeSwiperComponents/AudioAlbumAll";
import VideoCategoriesAll from "./HomeSwiperComponents/VideoCategoriesAll";
import SingleSeriesAll from "./HomeSwiperComponents/SingleSeriesAll";
import LiveStreamVideoAll from "./HomeSwiperComponents/LiveStreamVideoAll";
import LanguageAll from "./HomeSwiperComponents/LanguageAll";
import ArtistsAll from "./HomeSwiperComponents/ArtistsAll";
import ContentPartnerAll from "./HomeSwiperComponents/ContentPartnerAll";
import ChannelPartnerAll from "./HomeSwiperComponents/ChannelPartnerAll";
import LatestSeriesAll from "./HomeSwiperComponents/LatestSeriesAll";
import HomeCategoriesAll from "./HomeSwiperComponents/HomeCategoriesAll";
import StaticHomeSwiper from "./HomeSwiperComponents/StaticHomeSwiper";
import AudioCategoriesAll from "./HomeSwiperComponents/AudioCategoriesAll";
import ContinueWatchingAll from "./HomeSwiperComponents/ContinueWatchingAll";
import { allHomePageAction } from "../../Redux/Actions/apiActions";
import { useDispatch } from "react-redux";
import { getItem } from "../../Utils/localStorageUtils";


const AllHomePage = ({ homepage, isLoading, error, thumbnailTitle, settings }) => {
  const userRoleLocal = getItem('role')
  const dispatch = useDispatch()

  const [bodyScrollTop, setBodyScrollTop] = useState(0);
  const [isActionDispatched, setIsActionDispatched] = useState(false);

  const isLaptop = () => {
    const screenWidth = window.innerWidth;
    return screenWidth >= 1024 && screenWidth <= 2000;
  };
  const isMobile = () => {
    const screenWidth = window.innerWidth;
    return screenWidth >= 320 && screenWidth < 1024;
  };

  useEffect(() => {
    const handleScroll = () => {
      let bodyScrollTop = window.scrollY || document.documentElement.scrollTop;
      setBodyScrollTop(bodyScrollTop);
    };
    if (settings && Object.keys(settings).length) {
      const shouldDispatch = (!userRoleLocal && settings.access_free == 1) || userRoleLocal;
      window.addEventListener('scroll', handleScroll);
      if (isLaptop() && shouldDispatch && bodyScrollTop > 100 && !isActionDispatched) {
        dispatch(allHomePageAction());
        setIsActionDispatched(true);
      }
      else if (isMobile() && !isActionDispatched) {
        dispatch(allHomePageAction());
        setIsActionDispatched(true);
      }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [settings, userRoleLocal, bodyScrollTop, isActionDispatched]);

  return (
    <div className={`${homepage?.length && "paddingBottom "} pt-4`} style={{ overflow: "hidden" }}>
      {isLoading ? Array.from({ length: 2 }).map((_, n) => <StaticHomeSwiper key={n} />)
        : homepage?.map((item, index) => {
          return (
            item?.source == "Live_Stream_based_on_Categories" ? item?.data.map((value) => <LiveStreamCategoriesAll value={value} key={item.id} />)
              : item?.source == "Videos_based_on_Categories" ? item?.data.map((value) => <VideoCategoriesAll value={value} ThumbnailTitle={thumbnailTitle} key={item.id} />)
                : item?.source == "Series_based_on_Genres" ? item?.data.map((value) => <SeriesCategoriesAll value={value} ThumbnailTitle={thumbnailTitle} key={item.id} />)
                  : item?.source == "Audio_based_on_Genre" || item?.source == " latest_viewed_Audios" ? item?.data.map((value) => <AudioCategoriesAll value={value} ThumbnailTitle={thumbnailTitle} key={item.id} />)
                    : item?.source == "Audio_Albums" || item?.source == "audios" || item?.source == "latest_viewed_Audios" ? <AudioAlbumAll item={item} key={item.id} />
                      : item?.source == "single_series" ? item?.data?.map((value) => <SingleSeriesAll item={item} value={value} ThumbnailTitle={thumbnailTitle} key={item.id} />)
                        : item?.source == "livestream_videos" || item?.source == "scheduled_publish_LiveStream" ? <LiveStreamVideoAll item={item} key={item.id} />
                          : item?.source == "Series_Genre" || item?.source == "liveCategories" || item?.source == "videoCategories" || item?.source == "Audio_Genre" ? <HomeCategoriesAll item={item} key={item.id} />
                            : item?.source == "AllLanguage" ? <LanguageAll item={item} key={item.id} />
                              : item?.source == "Artists" ? <ArtistsAll item={item} key={item.id} />
                                : item?.source == "content_partner" || item?.source == "Individual_ContentPartner" ? <ContentPartnerAll item={item} key={item.id} />
                                  : item?.source == "channel_partner" || item?.source == "Individual_channelPartner" ? <ChannelPartnerAll item={item} key={item.id} />
                                    : item?.source == "featured_videos" || item?.source == "latest_videos" || item?.source == "scheduled_publish_video" || item?.source == "video_schedule" ||
                                      item?.source == "latest_viewed_Videos" || item?.source == "Recommended_videos_site" || item?.source == "Recommended_videos_users" ||
                                      item?.source == "Recommended_videos_Country" ? <HomeVideoAll item={item} key={item.id} />
                                      : item?.source == "continue_watching" ? <ContinueWatchingAll item={item} key={item.id} />
                                        : item?.source == "latest_series" || item?.source == "latest_viewed_Episode" ? <LatestSeriesAll item={item} ThumbnailTitle={thumbnailTitle} key={item.id} />
                                          : null
          )
        }
        )}

    </div>
  );
};
const mapStateToProps = state => ({
  homepage: state.get_allHomePage_Reducer.homepage,
  isLoading: state.get_allHomePage_Reducer.isLoading,
  error: state.get_allHomePage_Reducer.error,
  thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle,
  settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(AllHomePage);