import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../Styles/episodeSwiper.css';
import { Navigation } from "swiper/modules";
import SingleSeasonDetails from './SingleSeasonDetails';
import { connect, useDispatch } from 'react-redux';
import { seasonDetailsAction } from '../../../Redux/Actions/apiActions';
import EpisodesSkeletonTabs from '../../../Pages/HomePage/HomeSwiperComponents/EpisodesSkeletonTabs';
import { useTranslation } from 'react-i18next';

const EpisodesTabsection = ({ seriesDetailsData, settings, isLoading, seasonDetailsData }) => {
    const [seasons, setSeasons] = useState([]);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState(seasons[0]?.id);
    const [currentSeasonId, setCurrentSeasonId] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const episodeLength = seriesDetailsData?.series_season?.length || 0;
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const lastSelectedTab = localStorage.getItem('activeTab');
    const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json", };
    const [episodes, SetEpisodes] = useState([])
    const generateSeasons = (length) => {
        return Array.from({ length }, (_, i) => {
            const seasonId = seriesDetailsData.series_season[i]?.id || i + 1;
            setCurrentSeasonId(seasonId)
            return {
                id: i + 1,
                seasonName: `Season ${i + 1}`,
                seasonId: seasonId,
                content: (
                    <SingleSeasonDetails key={seasonId} seriesDetailsData={seriesDetailsData}
                        settings={settings} seasonNumber={`S${i + 1}`} seasonId={seasonId} />
                )
            };
        });
    };
    useEffect(() => {
        const episodeLength = seriesDetailsData?.series_season?.length;
        const generatedSeasons = generateSeasons(episodeLength).reverse();
        setSeasons(generatedSeasons);
        setLoading(false);
        if (!user_role) {
            dispatch(seasonDetailsAction(null, 9, "web", seriesDetailsData.id, generatedSeasons[0]?.seasonId, headers));
        }
    }, [seriesDetailsData]);

    useEffect(() => {
        const storedTabId = localStorage.getItem('currentTab');
        if (storedTabId && seasons.some(season => season.id === parseInt(storedTabId))) {
            setCurrentTab(parseInt(storedTabId));
            setActiveTab(seasons.findIndex(season => season.id === parseInt(storedTabId)));
        } else {
            setActiveTab(0);
            setCurrentTab(seasons[0]?.id);
        }
    }, [seasons]);

    const handleTabClick = (value) => {
        setActiveTab(value?.a);
        setCurrentTab(value?.a);
        localStorage.setItem('activeTab', value?.a);
        localStorage.setItem('currentTab', value?.a)
        localStorage.setItem('currentSeason', value?.b)
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(seasonDetailsAction(null, 9, "web", seriesDetailsData?.id, value?.b, headers))
                : user_role && dispatch(seasonDetailsAction(user_id, user_role, "web", seriesDetailsData?.id, value?.b, headers));
        }
    };

    return (
        <div> {loading ? (
            <EpisodesSkeletonTabs />
        ) :
            <Swiper modules={[Navigation]} loop={false} pagination={{ clickable: true }}
                navigation={true} spaceBetween={30} lazy={true} slidesPerGroup={1} className={`swiper-container episodeSwiper`}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        slidesPerGroup: 2,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        slidesPerGroup: 3,
                    },
                    1200: {
                        slidesPerView: 8,
                        spaceBetween: 30,
                        slidesPerGroup: 8,
                    },
                    1440: {
                        slidesPerView: 10,
                        spaceBetween: 30,
                        slidesPerGroup: 10,
                    }
                }}>
                {seasons?.map((season, index) => (
                    <SwiperSlide key={index} className={`swiper-slide ${index == activeTab ? 'active' : ''}`}>
                        <div key={index} id={season?.id}
                            className={`${currentTab == season.id ? 'mb-1 tab2Text theme-button-tab-color' : 'mb-1 tab2Text'} ${index == 0 ? 'me-2' : 'me-2'}`}
                            style={{ cursor: 'pointer' }} disabled={currentTab == season.id}
                            onClick={() => handleTabClick({ a: season.id, b: season?.seasonId })}>
                            {t(season.seasonName)}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        }
            <div className="content mb-2">
                {seasons?.map((season, i) => (
                    <div key={i}>
                        {currentTab === season.id && <div >{(season.content)}</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};
const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    seasonDetailsData: state.get_seasonDetails_Reducer.seasonDetailsData,
    isLoading: state.get_seasonDetails_Reducer.isLoading,
    error: state.get_seasonDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(EpisodesTabsection)