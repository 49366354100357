// External Libraries and Packages
import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
// Internal Styles
// Internal Components
import ChannelAllRoutes from "./ChannelAllRoutes.jsx";
import ContentAllRoutes from "./ContentAllRoutes.jsx";
import AdvertiserAllRoutes from "./AdvertiserAllRoutes.jsx";
import LoginIndex from "../Pages/Auth/LoginIndex.jsx";
import SignUpIndex from "../Pages/Auth/SignUpIndex.jsx";
import UserAllRoutes from "./UserAllRoutes.jsx";
import Error404 from "../components/Error404.jsx";
import DynamicPage from "../Pages/DynamicPage.jsx";
import ForgetPasswordThemeTwo from '../Pages/Auth/ForgetPasswordThemeTwo'
import VerifyOtp from '../Pages/Auth/VerifyOtp'
import Iyzico_Sucesspage from '../components/Payment_Registeration/Iyzico_Sucesspage'
import Iyzico_Failerpage from '../components/Payment_Registeration/Iyzico_Failerpage'
import PPV_Failerpage from '../components/Payment_Registeration/PPV_Failerpage'

function AllRoutes({ props }) {
  const { currentTheme, TranslateCheckOut, lang } = props;
  const langPrefix = TranslateCheckOut?.translate_checkout ? `/:lang` : '';
  return (
    <Routes>
      {/* Redirect to language-specific route if translation is enabled */}
      {TranslateCheckOut?.translate_checkout && (<Route path="/" element={<Navigate to={`/${lang}`} replace />} />)}




      {/* User SignUp and Login Routes */}
      <Route path={`${langPrefix}/signup`} element={<SignUpIndex />} />
      <Route path={`${langPrefix}/login`} element={<LoginIndex />} />
      <Route path={`${langPrefix}/page/:type`} element={<DynamicPage />} />

      {/* {Forget route} */}
      <Route path={`${langPrefix}/forget`} element={<ForgetPasswordThemeTwo />}></Route>
      <Route path={`${langPrefix}/verify/:code`} element={<VerifyOtp />}></Route>
      <Route path={`${langPrefix}/successpage`} element={<Iyzico_Sucesspage />}></Route>
      <Route path={`${langPrefix}/failerpage`} element={<Iyzico_Failerpage />}></Route>
      <Route path={`${langPrefix}/ppv/failerpage`} element={<PPV_Failerpage />}></Route>

      {/* {Forget route} */}

      {/* Protect Router with login need */}
      <Route path={`${langPrefix}/*`} element={<UserAllRoutes props={{ currentTheme: currentTheme }} />} />
      <Route path={`${langPrefix}/channel/*`} element={<ChannelAllRoutes />} />
      <Route path={`${langPrefix}/cpp/*`} element={<ContentAllRoutes />} />
      <Route path={`${langPrefix}/advertiser/*`} element={<AdvertiserAllRoutes />} />
      {/* Protect Router with login need end */}

      {/* Erro Page */}
      <Route path={`*`} element={<Error404></Error404>}></Route>
    </Routes>
  );
}

export default AllRoutes;