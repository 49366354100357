import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
body {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
 button {
    background-color:${({ theme }) => theme.button} !important;
    color:${({ theme }) => theme.buttonText} !important;
  }
a{
  color:${({ theme }) => theme.text} !important;
  }
 .theme-text-color {
    color: ${({ theme }) => theme.text} !important;
  }
  .theme-text-color-secondary{
    color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-text-color-body{
    color: ${({ theme }) => theme.body} !important;
  }
  .theme-text-color-button{
    color: ${({ theme }) => theme.button} !important;
  }
.theme-bg-color{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .theme-bg-color-secondary{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-bg-color-reverse{
    background-color: ${({ theme }) => theme.text} !important;
  }
  .theme-button-bg-color{
  background-color:${({ theme }) => theme.button} !important;
  }
  .theme-button-text-color{
    color:${({ theme }) => theme.buttonText} !important;
  }
  .theme-button-tab-color{
    color:${({ theme }) => theme.button} !important;
  }
  
  .theme-button-inactive-category{
  background: none!important;
  transition: none !important;
  border:2px solid ${({ theme }) => theme.button};
  }
  .theme-button-active-category{
  border-radius: 50px; 
  font-size: 16px;
  background: ${({ theme }) => theme.button};
  transition: none !important;
  }
  .theme-bg-category-color{
    background-color:#2c2c2c!important;
  }
  .bgButton{
    background-color:${({ theme }) => theme.button} !important;
    color:${({ theme }) => theme.buttonText} !important;
  }
  .theme-border-body-color{
  border-color: ${({ theme }) => theme.body} !important;
  }
  .theme-border-color{
    border-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-border-text-color{
  border-color: ${({ theme }) => theme.text} !important;
  }
  .theme-border-button-color{
  border-color: ${({ theme }) => theme.button} !important;
  }
  .theme-border-button-payment{
  border: 2px solid  ${({ theme }) => theme.button} !important;
  }

  .option-card input:checked+.radio-button {
  border-color: ${({ theme }) => theme.button} !important;
  background: radial-gradient(circle, ${({ theme }) => theme.button} 40%, transparent 50%) !important;
  }
 
 .bootstrapTable table>thead>tr>th{
    border-bottom: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }
  .bootstrapTable table>tbody>tr{
    border-bottom: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }
  .drag-border-bottom{
    border-bottom:2px solid #fff;
  }
  .drag-border-bottom-item{
    border-bottom:1px solid #fff;
  }
  .theme-border-secondary{
    border: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }
  .theme-border-color-reverse{
    border: 1px solid ${({ theme }) => theme.body} !important;
  }
  .theme-border-bottom-secondary{
    border-bottom: 2px solid ${({ theme }) => theme.secondarycolor} !important;
  }
  .custom-placeholder::placeholder{
    color:${({ theme }) => theme.text} !important;
    font-family:"Poppins";
  }
  .bootstrapTable .page-link {
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text};
    border-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .bootstrapTable .active.page-item > .page-link{
    border-color : #0d6efd;
    background-color:#0d6efd;
  }
  .adminHeadContainer .dropdown-item:hover, .adminHeadContainer .dropdown-item:focus{
    background-color: ${({ theme }) => theme.secondarycolor};
  }
  .editdropdown-menu a:hover{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .commonActionPadding:hover {
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  input::placeholder{
    color: ${({ theme }) => theme.text} !important;
  }
  .sideMenu.active{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .bannerPopContainer {
  background-image: linear-gradient(-90deg, transparent, ${({ theme }) => theme.body});
  }
  
  th{
    color: ${({ theme }) => theme.text} !important;
  }
  td{
    color: ${({ theme }) => theme.text} !important;
  }
  .form-select-full-border{
    border: 1px solid ${({ theme }) => theme.text} !important;
  }
  .css-d7l1ni-option{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-1nmdiq5-menu{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-d7l1ni-option:hover{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-1nmdiq5-menu:hover{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .rti--container{
    background-color: ${({ theme }) => theme.secondarycolor};
    color: ${({ theme }) => theme.text} !important;
    border:0 !important;
  }
  .rti--input{
    background-color: ${({ theme }) => theme.secondarycolor};
    color: ${({ theme }) => theme.text} !important;
  }
  .rti--tag{
    background-color: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .css-t3ipsp-control{
    background-color: ${({ theme }) => theme.secondarycolor};
  }
  table{
    --bs-table-bg :  ${({ theme }) => theme.body} !important;
  }
  .theme-text-color-list::after {
    background: ${({ theme }) => theme.text} !important;
  }
  .theme-bg-color-border-right{
    border-right: 1px solid ${({ theme }) => theme.text} !important;
  }
  .play-skip-button-center > * {
  color:${({ theme }) => theme.button} !important
  }
  .spinner{
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid ${({ theme }) => theme.button} !important; 
  border-right: 3px solid${({ theme }) => theme.button} !important; 
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  }
  .custom-fullscreen-button{
   color:${({ theme }) => theme.button} !important
  }
  .my-video-dimensions .vjs-control-bar button {
  color:${({ theme }) => theme.button} !important
  }
  .theme-text-color-bg {
    background-color: ${({ theme }) => theme.text} !important;
  }
  .headingText{
  color: ${({ theme }) => theme.text};
  }
  .theme-button-top-border {
    border-top: 1px solid ${({ theme }) => theme.text} !important;
    }
  .theme-button-bottom-border {
    border-bottom: 1px solid ${({ theme }) => theme.text} !important;
    }
  .theme-border-border-color {
    color: ${({ theme }) => theme.body};
    }
  .theme-border-border-color-all {
    border-bottom: 1px solid ${({ theme }) => theme.text} !important;
    }
  .toggleButton-border{
    border: 1px solid ${({ theme }) => theme.button} !important;
  }
  .bg-image-gradian::before{
    background-image: linear-gradient(to bottom, transparent, ${({ theme }) => theme.secondarycolor}, ${({ theme }) => theme.secondarycolor});
  }
  .linearShadow::before{
  background: linear-gradient(90deg, ${({ theme }) => theme.body} 15%, transparent 80%);
  }
  .rtl  .linearShadow::before{
  background: linear-gradient(270deg, ${({ theme }) => theme.body} 15%, transparent 80%);
  }
  .plan-lineargrade{
  background: linear-gradient(to right, ${({ theme }) => theme.button}, #0000) !important;
  }
  header{
    background-color: ${({ theme }) => theme.body};
  }
  a{
    color: ${({ theme }) => theme.text};
  }
  a:hover{
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }
  div1{
    background-color: ${({ theme }) => theme.body};
  }
  .listChip:hover {
  background-color: ${({ theme }) => theme.body};
  }
  #pageDropDown{
    background-color: ${({ theme }) => theme.body};
    margin-right:13px;
    border: 1px solid ${({ theme }) => theme.secondarycolor} !important;
    color: ${({ theme }) => theme.text} !important;
  }
  .react-bs-table-sizePerPage-dropdown .dropdown-menu {
  background-color: ${({ theme }) => theme.body} !important;
  color: ${({ theme }) => theme.text} !important;
  }
  .css-1p3m7a8-multiValue{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .css-wsp0cs-MultiValueGeneric{
    color: ${({ theme }) => theme.text} !important;
  }
  .react-tel-input .country-list .search{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .react-tel-input .country-list .search .search-box{
    background-color: ${({ theme }) => theme.body} !important;
    border: 1px solid ${({ theme }) => theme.text} !important;
  }
  .musicFollowBtn.inActive:hover{
  background-color: ${({ theme }) => theme.secondarycolor} !important;
  color: ${({ theme }) => theme.text} !important;
  }
  .musicHomePageSwiper .swiper-slide:hover, .musicAlbumSwiper .swiper-slide:hover {
  background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .purchaseBtn:hover .infoIcon{
  // color: ${({ theme }) => theme.body} !important;
  }
  .custom-shadow{
  box-shadow: 0px 0px 71px -18px  ${({ theme }) => theme.text} !important;
  }
  .progress{
    background-color: ${({ theme }) => theme.secondarycolor} !important;
  }
  .highlighted {
  color: ${({ theme }) => theme.button} !important
  } .highlightfillIcon {
  color: ${({ theme }) => theme.button} !important
  }
  .icontabBg{
  fill:${({ theme }) => theme.button} !important
  }
  .tabHeading.highlightTabColor::after {
    background-color:${({ theme }) => theme.button} !important;
  }
   .my-video-dimensions.vjs-theme-city .vjs-play-progress{
    background-color:${({ theme }) => theme.button} !important;
    }
  .playlistHeading::before{
  background-color:${({ theme }) => theme.button} !important;
  }
  .searchInputHeader::placeholder,.searchInputHeader{
  color: ${({ theme }) => theme.buttonText} !important
  }
  .homeListImage::after { 
    background: linear-gradient(to top, ${({ theme }) => theme.secondarycolor} , transparent);
  }
  // theme two 
.swiperThemeTwo .circleAfter::after, .movieSwiper .circleAfter::after {
    background-color:${({ theme }) => theme.button} !important;
  }
  .themeTwoLink.active::after,.themeTwoLink:hover:after {
  background-color:${({ theme }) => theme.button} !important;
  }
 .partnerSwiper .swiper-pagination-bullet, .movieSwiper .swiper-pagination-bullet{
    background-color:${({ theme }) => theme.text} !important;
  }
   .partnerSwiper .swiper-pagination-bullet-active, .movieSwiper .swiper-pagination-bullet-active{
  background-color:${({ theme }) => theme.button} !important;
  }
.popupDetailsThemeTwo::after {
    background-color: ${({ theme }) => theme.secondarycolor};
  }
 .profileThemeTwo.active{
    background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .profileThemeTwo.active::before{
    background-color:${({ theme }) => theme.button} !important;
  }
  .themeTwoFaq .accordion-button::after {
  background-color:${({ theme }) => theme.text} !important;
  }
  .homeSwiperContainer .dropdown-item:hover,
  .homeSwiperContainer .dropdown-item:focus {
  color:${({ theme }) => theme.button} !important;
  }
  .flickNexsTimeLine{
  background-color:${({ theme }) => theme.body} !important;
  }
  .flickNexsTimeLineMins,.flickNexsSideBar,.timeLineChannelNameHide,.timeLineChannelName,.mainTrack{
  background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .mainTrack::-webkit-scrollbar-track {
  background-color:${({ theme }) => theme.body} !important;
  }
 .mainTrack::-webkit-scrollbar-thumb {
  background-color:${({ theme }) => theme.button} !important;
  }
  .languageDrop  .dropdown-item:hover,  .languageDrop  .dropdown-item:focus{
  background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .hoverEffectSecondary:hover{
  background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .audioBanner::after{
    background-image: linear-gradient(90deg,  ${({ theme }) => theme.body} 35%, transparent);
  }
  .playButton.yes::before{
    background-color:${({ theme }) => theme.button} !important;
  }
  .playButton.one::after{
    color:${({ theme }) => theme.button} !important;
  }
  .avatarProfile.add:hover {
    background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .profileImageUpdateMulti:hover:before{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .secondary:hover{
    background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .primary:hover{
  background-color:${({ theme }) => theme.body} !important;
  }
  .custom-country .stdropdown-container{
    border-bottom: 1px solid ${({ theme }) => theme.text} !important;
  }
  .stdropdown-menu{
  background-color:${({ theme }) => theme.secondarycolor} !important;
  }
  .active.music-link >.musicSideBarIcons{
    color:${({ theme }) => theme.button} !important;
  }
  .loaderFixed{
  background-color:${({ theme }) => theme.secondarycolor} !important;
  }
 @media screen and (max-width: 768px) {
  .themeTwoNavMobileFixed{
    background-color: ${({ theme }) => theme.body} !important;
  }
  .scrollingPlaceholder::placeholder {
    color:${({ theme }) => theme.body} !important;
    }
  }
`;