import "../../Styles/AccountPage.css";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMyContext } from "../Layout/CommonProvider";
import MessageBox from "../MessageBox";
import { IconMyCamera, IconMyUpload } from "../../assets/icons/MyIcons";
import { getItemToken } from "../../Utils/localStorageUtils";
import usePpvGlobalImageConfig from "../Api/Rentppv_global_change";
import { profileDetailsAction } from "../../Redux/Actions/apiActions";
import ButtonWithLoader from "../../Pages/Music/CommonComponents/ButtonWithLoader";
import { FaArrowRight, FaSpinner } from "react-icons/fa";

const AccountPage = (props) => {

  const { profileDetails } = props
  const { lang } = useParams()

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { navigateTranslateChecker } = useMyContext()
  const [accountdata, setAccountData] = useState([]);
  const [profileImage, setProfileImage] = useState()
  const [loading, setLoading] = useState(true);
  const [formDataError, setFormDataError] = useState({})
  const [datatranscation, setDatatranscation] = useState({});

  const nameRef = useRef()
  const lastRef = useRef()

  const handleCoverImageChange = async (event, errorKey) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(selectedFile);
      };
      // await handleUpdate()
      reader.readAsDataURL(selectedFile);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true // AM/PM format
    };

    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
      <>
        <div>{formattedDate}</div>
      </>
    );
  }



  const calculateSubscriptionDuration = (days) => {
    if (days >= 365) {
      const years = Math.floor(days / 365);
      return years > 1 ? `${years} Years` : `${years} Year`;
    } else if (days >= 30) {
      const months = Math.floor(days / 30);
      return months > 1 ? `${months} Months` : `${months} Month`;
    } else {
      return `${days} Days : ${days} Day`;
    }
  };

  const subscriptionDuration = calculateSubscriptionDuration(datatranscation?.subscriptions_days);

  const formValidation = () => {
    let formIsValid = true;
    if (!accountdata?.name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        name: "First name can't be empty",
      }));
      nameRef.current.focus();
      formIsValid = false;
    }
    if (!accountdata?.last_name) {
      setFormDataError((prevFormData) => ({
        ...prevFormData,
        last_name: "Last name can't be empty",
      }));
      lastRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const handleUpdate = async (e) => {
    if (formValidation()) {
      setLoading(true);
      let formData = new FormData()
      formData.append("Login_device_type", "web")
      formData.append("name", accountdata?.name)
      formData.append("email", accountdata?.email)
      formData.append("last_name", accountdata?.last_name)
      formData.append("avatar", profileImage ? profileImage : accountdata?.avatar)
      formData.append("DOB", accountdata?.DOB)

      try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Profile-details-update`, formData, { headers: getItemToken('access_token') })
        const result = response?.data
        if (result?.status) {
          toast.success(t("Account Updated Successfully"));
          dispatch(profileDetailsAction());
          setProfileImage(null)
          setLoading(false);
        }
        else { toast.error(t("Request failed with status: " + response?.status)) }
      } catch (error) {
        const errorMessage = error?.response?.data?.message
        toast.error(t(errorMessage))
        setLoading(false);
      }
    }
  };

  const userDetails = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_Baseurl}/Front-End/user-details`, {
        method: "POST",
        headers: getItemToken('access_token'),
      });
      const result = await response.json();
      setDatatranscation(result.transaction_details?.current_Subscription);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction data", error);
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setAccountData({
      ...accountdata,
      [name]: value,
    });
    setFormDataError((prev) => ({
      ...prev,
      [name]: ""
    }))
  }

  useEffect(async () => {
    setAccountData(profileDetails?.Profile_details);
  }, [profileDetails]);

  useEffect(() => {
    userDetails();
  }, []);


  const { currency, currencySymbol: fetchedCurrencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();

  console.log(profileDetails, "profileDetails")

  // const cancelCheckoutiyzico = async () => {
  //   try {
  //     const currentUrl = window.location.href;
  //     const locale = lang === "tr" ? "tr" : "en";
  //     const paymentId = profileDetails?.current_subscriptions_details?.PaymentGateway?.subscriptions_id;
  //     const conversationId = profileDetails?.current_subscriptions_details?.PaymentGateway?.subscriptions_id;
  //     const ip = locationResponselocationResponse?.ip;
  //     const Login_user_id = profileDetails?.id;

  //     // Ensure correct condition for Iyzico & PaymentGateway ID
  //     if (profileDetails?.current_subscriptions_details?.PaymentGateway?.name === "Iyzico" &&
  //       profileDetails?.current_subscriptions_details?.PaymentGateway?.id === 5) {

  //       const editInputvalue = {
  //         paymentId: paymentId,
  //         conversationId: conversationId,
  //         locale: locale,
  //         ip: ip,
  //         reason: "OTHER",
  //         description: "Customer requested cancellation",
  //         Login_device_type: "web",
  //         Login_user_id: Login_user_id,
  //         redirecturl: currentUrl,
  //       };

  //       const response = await axios.post(
  //         `${process.env.REACT_APP_Baseurl}/Front-End/Iyzico-cancel-subscriptionasdfasf`,
  //         editInputvalue,
  //         {
  //           headers: { Authorization: `Bearer ${getItemToken('access_token')}` },
  //         }
  //       );

  //       if (response?.data?.status === true) {
  //         const paymentPageUrl = response?.data?.paymentPageUrl; // Ensure correct URL field
  //         console.log(response?.data, "response?.data");
  //         if (paymentPageUrl) {
  //           window.location.href = paymentPageUrl; // Redirect
  //         } else {
  //           toast.error("Invalid payment page URL.");
  //         }
  //       } else {
  //         toast.error(response?.data?.errorMessage || "Payment cancellation failed.");
  //       }
  //     } 
  //   } catch (error) {
  //     console.error("Error initializing checkout:", error);
  //     toast.error("Failed to initialize checkout. Please try again.");
  //   }
  // };

  const [loadingcancelCheckoutiyzico, setLoadingcancelCheckoutiyzico] = useState(false);


  const cancelCheckoutiyzico = async () => {
    setLoadingcancelCheckoutiyzico(true); // Start loading
    const currentUrl = window.location.href;
    const subscriptionReferenceCode = profileDetails?.Profile_details?.subscriptions_id;
    const Login_user_id = profileDetails?.Profile_details?.id;

    const editInputvalue = {
      subscriptionReferenceCode: subscriptionReferenceCode,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_IYZICO_PAYMENT}/cancel-payment`,
        editInputvalue,
        { headers: getItemToken('access_token') }
      );

      const formattedDate = new Date().toISOString().slice(0, 19).replace("T", " ");

      if (response?.status === 200 && response?.data?.status === "success") {
        // const Cancellation = response?.data?.status;
        const editInputvaluecancelstore = {
          subscription_id: subscriptionReferenceCode,
          Login_device_type: "web",
          Login_user_id: Login_user_id,
          current_date: formattedDate,
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_Baseurl}/Front-End/Iyzico-cancel-subscription`,
            editInputvaluecancelstore,
            { headers: getItemToken('access_token') }
          );
          toast.success(t("Membership Cancelled"));
          if (response?.status === 200) {
            setTimeout(() => {
              window.location.href = currentUrl;
            }, 1000);
          } else {
            window.location.href = currentUrl;
          }
        } catch (error) {
          toast.error("Failed to cancel subscription. Please try again.");
        }
      } else {
        toast.error(`${response?.data?.errorMessage || "Cancellation failed"}`);
      }
    } catch (error) {
      console.error("Error initializing checkout:", error);
      toast.error("Failed to initialize checkout. Please try again.");
    } finally {
      setLoadingcancelCheckoutiyzico(false); // Stop loading
    }
  };

  // const cancelCheckoutiyzico = async () => {
  //   setLoadingcancelCheckoutiyzico(true); // Start loading
  //   const currentUrl = window.location.href;
  //   const subscriptionReferenceCode = profileDetails?.Profile_details?.subscriptions_id;
  //   const conversationId = "f8e05c99-5cdf-4755-91c9-a94c48140bf4";
  //   const ip = locationResponselocationResponse?.ip;
  //   const Login_user_id = profileDetails?.Profile_details?.id;

  //   const editInputvalue = {
  //     subscriptionReferenceCode: subscriptionReferenceCode,
  //     // conversationId: conversationId,
  //     // redirecturl: currentUrl,
  //   };

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_IYZICO_PAYMENT}/cancel-payment`,
  //       editInputvalue,
  //       { headers: getItemToken('access_token') }
  //     );

  //     if (response?.status === 200 && response?.data?.status === "success") {
  //       console.log(response?.data, "response?.data");
  //       const Cancellation = response?.data?.status
  //       const editInputvaluecancelstore = {
  //         subscription_id: subscriptionReferenceCode,
  //         Login_device_type: "web",
  //         Login_user_id: Login_user_id,
  //       };

  //       try {
  //         const response = await axios.post(
  //           `${process.env.REACT_APP_Baseurl}/Front-End/Iyzico-cancel-subscription`,
  //           editInputvaluecancelstore,
  //           { headers: getItemToken('access_token') }
  //         );

  //         toast.success(`Cancellation ${Cancellation}`);

  //         if (response?.status === 200) {

  //           // console.log(response?.data, "apiresponse?.data");
  //           window.location.href = currentUrl; // Uncomment if you want a redirect
  //           setTimeout(() => {
  //             window.location.href = currentUrl;
  //           }, 1000);

  //         } else {
  //           // toast.error(`${response?.data?.message || "Cancellation failed"}`);
  //           // console.log(response?.data, "apiresponse?.data");
  //           window.location.href = currentUrl;
  //         }
  //       } catch (error) {
  //         // console.error("Error cancelling subscription:", error);
  //         // toast.error("Failed to cancel subscription. Please try again.");
  //       }

  //     } else {
  //       toast.error(`${response?.data?.errorMessage || "Cancellation failed"}`);
  //       console.log(response?.data, "response?.data");
  //     }
  //   } catch (error) {
  //     console.error("Error initializing checkout:", error);
  //     toast.error("Failed to initialize checkout. Please try again.");
  //   } finally {
  //     setLoadingcancelCheckoutiyzico(false); // Stop loading
  //   }
  // };




  return (
    <>

      <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content theme-bg-color">
            <div className="modal-header">
              <h5 className="modal-title theme-text-color" id="staticBackdropLabel">{t("Cancel Membership")}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body rent_video_page_ul_li">
              <div className="row">
                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                <div className='row col-lg-12'>
                  <h6>{t("Are you sure you want to cancel your membership?")}</h6>

                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="btn btn-primary w-50"
                onClick={() => cancelCheckoutiyzico()}
                disabled={loadingcancelCheckoutiyzico}
              >
                {loadingcancelCheckoutiyzico ? (
                  <FaSpinner />
                ) : (
                  t("Proceed to Cancel")
                )}
              </button>

              {/* <button type="button" class="btn btn-primary" onClick={() => cancelCheckoutiyzico()}>{t("Proceed to Cancel")} </button> */}
            </div>
          </div>
        </div>
      </div>


      <section className="container-fluid account px-2  px-md-3 px-lg-5">
        <h2 className="text-center theme-text-color py-4">{t("My Account")}</h2>
        <div className="row m-0">
          <div className="col-12 col-md-6  px-2 px-md-4 ">
            <h5 className="accountTitle theme-text-color pb-4 d-md-none d-block ">{t("Display Picture")}</h5>
            <div className='position-relative profileImageUpdate  d-md-none d-block  mb-5 ' >
              <img key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} className="profileImageUpdate rounded-circle overflow-hidden  object-fit-cover" />
              <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
              </span>
              <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
              <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
            </div>
            <h5 className="accountTitle theme-text-color pb-4 ">{t("Personal Details")}</h5>

            <MessageBox text={t("First Name")} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="name" ref={nameRef} className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.name} onChange={handleInputChange} />
            {formDataError?.name && (<MessageBox text={`${formDataError?.name}`} classname='errorred mb-2 d-block' />)}

            <MessageBox text={t("Last Name")} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="last_name" ref={lastRef} className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.last_name} onChange={handleInputChange} />
            {formDataError?.last_name && (<MessageBox text={`${formDataError?.last_name}`} classname='errorred mb-2 d-block' />)}

            <MessageBox text={t("Mobile Number")} classname='account-label mb-2 theme-text-color d-block' />
            <input type="number" name="mobile" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.mobile} onChange={handleInputChange} disabled={true} />

            <MessageBox text={t("Email")} classname='account-label mb-2 theme-text-color d-block' />
            <input type="email" name="email" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.email} onChange={null} disabled={true} />

            <MessageBox text={t("Date of Birth")} classname='account-label mb-2 theme-text-color d-block' />
            <input type="date" name="DOB" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.DOB} onChange={handleInputChange} />
            <div className="d-flex align-items-center justify-content-between mt-3 mb-5 ">
              <Link to={navigateTranslateChecker(`/verify/forget`)} className="text-decoration-underline">  <MessageBox text={'Change Password'} classname='account-input theme-text-color d-block' /></Link>
              <ButtonWithLoader action={handleUpdate} text="Update" loading={loading} className='bgButton px-3 py-2 rounded-2 d-block width-max ms-auto ' />
            </div>
          </div>

          <div className="col-12 col-md-6  px-2 px-md-4">
            <h5 className="accountTitle theme-text-color pb-4 d-md-block d-none ">{t("Display Picture")}</h5>
            <div className='position-relative profileImageUpdate  d-md-block d-none  mb-5 ' >
              <img key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} className="profileImageUpdate rounded-circle overflow-hidden  object-fit-cover" />
              <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
              </span>
              <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
              <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} />
            </div>
            {/* <h5 className="accountTitle theme-text-color pb-4 ">{t("Tv Activation Code")}</h5>
            <MessageBox text={'TV Code'} classname='account-label mb-2 theme-text-color d-block' />
            <input type="text" name="activation_code" className="account-input mb-4 w-100 theme-text-color bg-transparent  pe-3 py-2 bottom-border theme-border-color" value={accountdata?.activation_code} onChange={null} disabled /> */}

            {accountdata?.role == 1 || accountdata?.role == "1" ? (
              <>
                <h5 className="accountTitle theme-text-color pb-4 ">{t("Membership Settings")}</h5>
                <MessageBox text={t("Current Membership")} classname='account-label mb-2 theme-text-color' />
                <MessageBox text={accountdata?.package} classname='accountTitle px-2 mb-2 theme-text-color' />
              </>
            ) : null}


            {/* {accountdata?.role !== 1 || accountdata?.role !== "1" && accountdata?.subscription_status === 0 || accountdata?.subscription_status === "0" ? (
              <>
                <h5 className="accountTitle theme-text-color pb-4 ">{t("Subscriptions")}</h5>
                <MessageBox text={'Why Wait When You Can Enjoy All Rounder'} classname='account-input mb-2 theme-text-color' />
                <MessageBox text={'Entertainment!'} classname='accountTitle px-2 mb-2 theme-text-color' />
                <Link to={navigateTranslateChecker(`/plan`)} className="bgButton width-max d-block my-3 px-3 py-2 rounded-2">
                  <MessageBox text="Become a Subscriber"></MessageBox>
                </Link>
              </>
            ) : null} */}

            {/* {!(accountdata?.role == 1 || accountdata?.role == "1" && (accountdata?.subscription_status == 0 || accountdata?.subscription_status == "0")) ? (
              <>
                <h4 className="accountTitle theme-text-color pb-4 ">{t("Subscriptions")}</h4>
                <MessageBox text={t("Get unlimited access to 1000+ videos")} classname='account-input mb-2 theme-text-color' />
                <Link to={navigateTranslateChecker(`/plan`)} className="bgButton width-max d-block my-3 px-3 py-2 rounded-2">
                  <MessageBox text="Become a Subscriber"></MessageBox>
                </Link>
              </>
            ) : null} */}

            {(accountdata?.role == 2 || (accountdata?.role == 3 && accountdata?.subscription_status == 0)) ? (
              <>
                <h4 className="accountTitle theme-text-color pb-4">{t("Subscriptions")}</h4>
                <MessageBox text={t("Get unlimited access to 1000+ videos")} classname='account-input mb-2 theme-text-color' />
                <Link to={navigateTranslateChecker(`/plan`)} className="bgButton width-max d-block my-3 px-3 py-2 rounded-2">
                  <MessageBox text={t("Become a Subscriber")} ></MessageBox>
                </Link>
              </>
            ) : null}

            {accountdata?.role == 3 ? (
              <>
                <div className="d-flex py-4 text-center align-items-center">

                  <h5 className="accountTitle theme-text-color">{t("YOUR MEMBERSHIP")}</h5>

                </div>
                {datatranscation !== null &&
                  <>
                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Price" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{`${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`} </p>

                      {Number(profileDetails?.Profile_details?.current_plan_subscriptions_details?.plan_trail_days) === 0 ||
                        Number(profileDetails?.Profile_details?.current_plan_subscriptions_details?.plan_trail_days) === null ? (
                        null
                      ) : Number(profileDetails?.Profile_details?.current_plan_subscriptions_details?.plan_trail_days) > 10 ? (
                        <MessageBox
                          text={`${profileDetails?.Profile_details?.current_plan_subscriptions_details?.plan_trail_days} days for free trial`}
                          classname="account-input theme-text-color"
                        />
                      ) : (
                        <MessageBox
                          text={`${profileDetails?.Profile_details?.current_plan_subscriptions_details?.plan_trail_days} day for free trial`}
                          classname="account-input theme-text-color"
                        />
                      )}


                    </div>

                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Next Billing Date :" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{formatDate(datatranscation?.subscriptions_ends_at)}</p>
                    </div>

                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Starting on" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{formatDate(datatranscation?.subscriptions_ends_at)}</p>
                      {/* <MessageBox text="Your card will automatically" classname='account-input theme-text-color '></MessageBox> */}
                    </div>


                    <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Be Charged" classname='account-input theme-text-color '></MessageBox>
                      <p className="account-label theme-text-color">{`${datatranscation?.currency?.symbol} ${datatranscation?.subscription_price} / ${subscriptionDuration}`} </p>
                    </div>

                    {/* <div className="d-flex align-items-center gap-3 mb-3">
                      <MessageBox text="Be Charged" classname='account-input  theme-text-color'></MessageBox>
                      <p className="account-label theme-text-color">{datatranscation?.currency?.symbol} {datatranscation?.subscription_price / subscriptionDuration}</p>
                      <MessageBox text="After your free trail ends" classname=' account-label theme-text-color'></MessageBox>
                    </div> */}



                  </>
                }
              </>
            ) : null}

            {/* {accountdata?.role == 3 || accountdata?.role == 1 ? <Link to={navigateTranslateChecker(`/transcation-list`)} className="bgButton width-max d-block mt-3 px-3 py-2 rounded-2">
              <MessageBox text="Transaction History"></MessageBox>
            </Link> : null} */}

            <div className="d-flex">
              {accountdata?.role != 1 || accountdata?.role != "1" ? (
                <Link to={navigateTranslateChecker(`/transcation-list`)} className="bgButton width-max d-block mt-3 px-3 py-2 rounded-2">
                  {t("Transaction History")}
                  {/* <FaArrowRight /> */}
                  {/* <MessageBox text={t("Transaction History")}> <FaArrowRight /> </MessageBox> */}
                </Link>
              ) : null}

              {accountdata?.role == 3 && accountdata?.subscription_status === 1 ? (
                <>
                  {datatranscation && datatranscation.PaymentGateway === "Iyzico" && datatranscation.PaymentGateway_id === 5 && (
                    <Link text-decoration-none data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="bgButton width-max d-block mt-3 px-3 py-2 rounded-2 ms-3">
                      {t("Cancelled Subscription")}
                    </Link>
                  )}
                </>
              ) : null}


            </div>

          </div>
        </div>
      </section >


    </>
  );
};
const mapStateToProps = state => ({
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});

export default connect(mapStateToProps)(AccountPage);