// External Libraries and Packages
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// Internal Styles
// Internal Components
import { getWishListAction } from "../Redux/Actions/apiActions";
import PageLoader from "../components/Loader/PageLoader";
import PageError from "../components/PageError";
import LayoutWishlistSwiper from "../components/ViewAll/Source/LayoutWishlistSwiper";
import { useTranslation } from "react-i18next";

const Wishlists = ({ settings, isLoading, getWishListData, error }) => {
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  useEffect(() => {
    if (settings && Object.keys(settings)?.length > 0) {
      if (!user_role) {
        try { dispatch(getWishListAction(null, 9, "web", headers)); }
        catch (error) { console.log(error) }
      } else {
        try { dispatch(getWishListAction(user_id, user_role, "web", headers)); }
        catch (error) { console.error("Error:", error); }
      }
    }
  }, [settings, user_role, user_id, dispatch, location]);
  // console.log(t(capitalizeText('Wishlist')))

  return (
    isLoading ? <PageLoader /> : error ? <PageError /> :
      <div className="paddingTop px-md-4 px-2 paddingBottom" style={{ overflowX: "hidden" }}>
        <h2 className="viewAllTitle py-md-4 py-2 text-center mb-0  theme-text-color ">{t(capitalizeText('Wishlist'))} </h2>
        {getWishListData?.videos?.length !== 0 && <LayoutWishlistSwiper mapData={getWishListData.videos} heading="Videos" sectionClass="pb-4" path="videos" viewAllLink="" />}
        {getWishListData?.series?.length !== 0 && <LayoutWishlistSwiper mapData={getWishListData?.series} heading="Series" sectionClass="pb-4" path="shows" viewAllLink="" />}
        {getWishListData?.live_stream_videos?.length !== 0 && <LayoutWishlistSwiper mapData={getWishListData?.live_stream_videos} heading="Live Streams" sectionClass="pb-4" path="live" viewAllLink="" />}
        {getWishListData?.Audios?.length !== 0 && <LayoutWishlistSwiper mapData={getWishListData?.Audios} heading="Audios" sectionClass="pb-4" path="music" viewAllLink="" />}
      </div>
  );
};

const mapStateToProps = (state) => ({
  getWishListData: state.get_wishList_Reducer.getWishListData,
  isLoading: state.get_wishList_Reducer.isLoading,
  error: state.get_wishList_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(Wishlists);

