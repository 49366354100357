// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
import { BsDownload } from "react-icons/bs";
// Internal Styles
import "../../../Pages/HomePage/Video_details.css"
import "../../../Styles/Details.css"
// Internal Components
import { liveDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../Error404';
import HelmetDetails from '../../HelmetDetails';
import { LikeAction, disLikeAction, watchLaterAction, wishListAction } from '../../../Pages/CommonMethods/CommonMethods';
import SocialMediaShareBox from "../../SocialMediaShareBox";
import { MdOutlineWatchLater, MdWatchLater } from "react-icons/md";
import { FiThumbsUp, FiThumbsDown, FiMinusCircle } from "react-icons/fi";
import { FaShareAlt } from "react-icons/fa";
import ToolTip from '../../ToolTip';
import Breadcrumb from '../../Breadcrumb';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../MessageBox';
import MoreLike from '../../MoreLike';
import usePpvGlobalImageConfig from "../../Api/Rentppv_global_change";
import axios from 'axios';
import { toast } from "react-toastify";
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";
import { useMyContext } from '../../Layout/CommonProvider';
import PageLoader from '../../Loader/PageLoader';
import { FaHeart, FaRegHeart } from "react-icons/fa";
const Livestreams = ({ liveDetailsData, isLoading, error, settings, livePayment_setting, profileDetails, settingsall }) => {
    const { lang, slug } = useParams();
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [domReload, setDomReload] = useState(false)
    const currentPageURL = window.location.href
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(liveDetailsAction(null, 9, "web", slug, headers))
                : user_role && dispatch(liveDetailsAction(user_id, user_role, "web", slug, headers));
        }
    }, [settings, user_role, domReload, location])

    const handleDOM = () => {
        setDomReload(!domReload)
    }
    const codeNumber = "2";

    const handleLikeClick = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            const likeStatus = liveDetailsData?.like == null || liveDetailsData?.like == 0 ? 1 : 0
            await LikeAction(user_id, userRole, 'web', liveDetailsData?.id, codeNumber, likeStatus);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDisikeClick = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            const likeStatus = liveDetailsData?.dislike == null || liveDetailsData?.dislike == 0 ? 1 : 0;
            await disLikeAction(user_id, userRole, 'web', liveDetailsData?.id, codeNumber, likeStatus);
            handleDOM();
        } catch (error) {
            console.error(error);
        }

    };


    const handleClickWishList = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            await wishListAction(user_id, userRole, 'web', liveDetailsData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    }

    const handleClickWatchLater = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            await watchLaterAction(user_id, userRole, 'web', liveDetailsData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    }
    const handlePlayButton = () => {
        {
            liveDetailsData?.users_livestream_visibility_status === true
                ? navigate(navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`))
                : navigate(navigateTranslateChecker(`/livestream/${liveDetailsData?.slug}`))
        }
    }

    const onButtonClick = () => {
        const pdfUrl = "Sample.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = `${liveDetailsData?.slug}.pdf`; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const htmlString = liveDetailsData?.details;

    const theObj = { __html: htmlString };
    const [time, setTime] = useState('00:00:00');
    const [duration, setDuration] = useState('00:00:00')
    useEffect(() => {
        if (liveDetailsData?.free_duration_time) {
            setTime(liveDetailsData?.free_duration_time);
        }
        if (liveDetailsData?.duration) {
            setDuration(liveDetailsData?.duration)
        }
    }, [liveDetailsData, location]);

    const convertTimeToSeconds = (time) => {
        const [hours, minutes, seconds] = time?.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };
    const totalSeconds = convertTimeToSeconds(time);
    // const formatDate = (date) => {
    //     const options = { day: '2-digit', month: 'long', year: 'numeric' };
    //     return new Date(date).toLocaleDateString('en-GB', options);
    // };
    // const formatDate2 = (date) => {
    //     const d = new Date(date);
    //     const day = String(d.getDate()).padStart(2, '0');
    //     const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    //     const year = d.getFullYear();

    //     return `${day}-${month}-${year}`;
    //   };
    const formatDate1 = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    };
    const date = liveDetailsData?.created_at;
    const formattedDate = formatDate1(date);

    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    const [convertedPrice, setConvertedPrice] = useState(null);

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = liveDetailsData?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, liveDetailsData?.ppv_price]);

    //   geo ip location integration 
    //   geo ip location integration 

    //    Iyzico payment integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = liveDetailsData?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });


    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = liveDetailsData?.ppv_price !== null ? liveDetailsData?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = liveDetailsData?.ContentPriceRate !== null
                    ? liveDetailsData?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const successpage = window.location.href;
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + liveDetailsData.slug;
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = liveDetailsData?.title || liveDetailsData?.name;
        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 2,
            source_id: liveDetailsData?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            pageredirectionslug: pageredirectionslug,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                district: city,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));



    }, [liveDetailsData, currency, settings, convertedPrice]);

    const initializeCheckoutiyzico = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };

    const initializeCheckoutStripe = async () => {

        const currentUrl = window.location.href; // Full URL of the current page
        const failureUrl = `${process.env.REACT_APP_Baseurl}/ppv/failerpage`;
        const currency = currency?.symbol;

        const formDatastripe = new FormData();
        formDatastripe.append("Login_device_type", "web");
        formDatastripe.append("unit_amount", liveDetailsData?.ppv_price);
        formDatastripe.append("currency", currency);
        formDatastripe.append("source_type", 2);
        formDatastripe.append("source_id", liveDetailsData?.id);
        formDatastripe.append("success_url", currentUrl);
        formDatastripe.append("failure_url", failureUrl);

        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/stripe-ppv-payment-link`, formDatastripe, { headers });
            if (response?.data?.status === true) {
                const paymentPageUrl = response?.data?.stripe_checkout_url;
                toast.success(`${response?.data?.message}`);
                window.location.href = paymentPageUrl; // Redirect
            } else if (response.data.status === "false") {
                toast.error(`${response.data.errorMessage}`);
            }
        } catch (error) {
            console.error("Error initializing checkout:", error);
            toast.error("Failed to initialize checkout. Please try again.");
        }
    };


    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 try {
    //                     const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
    //                     const country = locationResponse?.data?.country;
    //                     const country_code = locationResponse?.data?.country_code;
    //                     const city = locationResponse?.data?.city; // Assuming you need city here

    //                     if (city && country) { // Ensure both city and country are defined
    //                         showAlert = false;

    //                         const result = response.data;
    //                         const user_id = localStorage.getItem("user_id");
    //                         const web = "web";
    //                         const liveDetailsDataid = liveDetailsData?.id;

    //                         const formDataiyzico = new FormData();
    //                         formDataiyzico.append("Login_user_id", user_id);
    //                         formDataiyzico.append("Login_device_type", web);
    //                         formDataiyzico.append("source_type", 1);
    //                         formDataiyzico.append("source_id", liveDetailsDataid);
    //                         // formDataiyzico.append("tokenid ", result.token);
    //                         formDataiyzico.append("payment_id", result.paymentId);
    //                         formDataiyzico.append("regionname", country);
    //                         formDataiyzico.append("countryname", country);
    //                         formDataiyzico.append("cityname", city);

    //                         console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // Log the FormData content

    //                         const postResponse = await axios.post(
    //                             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-ppv-verify-store`,
    //                             formDataiyzico
    //                         );

    //                         if (postResponse.data.status_code === 200) {
    //                             toast.success(`Purchased video was Created Successfully`);
    //                         } else {
    //                             toast.error(`Purchased video was Created Failed`);
    //                         }
    //                     } else {
    //                         console.error('City or country is undefined');
    //                     }
    //                 } catch (locationError) {
    //                     console.error('Error retrieving location data:', locationError);
    //                 }
    //             }
    //         } catch (paymentError) {
    //             console.error('Error retrieving payment details:', paymentError);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, [liveDetailsData]); // Add `liveDetailsData` as a dependency if it's used in the effect


    // Iyzico payment integration 
    // Iyzico payment integration 



    const [paymentGateways, setPaymentGateways] = useState([]);

    const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

    const fetchPaymentGateways = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
                { headers: headers }
            );
            const paymentGateways = response?.data?.Payment_Gateways;
            setPaymentGateways(paymentGateways);
            // Set the active gateway to the first one by default
            setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGatewayClick = (gatewayName) => {
        setActiveGateway(gatewayName);
    };


    useEffect(() => {
        // fetchOptions();
        fetchPaymentGateways();
    }, []);


    const staticContent = {
        Stripe: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckoutStripe()}>Stripe Pay to the {liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</button>
                </div>
            </>
        ),
        PayPal: (
            <>
                <div>
                    <button className='btn w-100'>PayPal Pay to the {liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</button>
                </div>
            </>
        ),
        Razorpay: (
            <>
                <div>
                    <button className='btn w-100'>Razorpay Pay to the {liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</button>
                </div>
            </>
        ),
        Iyzico: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckoutiyzico()}>Iyzico Pay to the {liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</button>
                </div>
            </>
        ),
    };

    console.log(liveDetailsData?.publish_type)
    const handleReload = () => {
        const targetUrl = navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`);
        navigate(targetUrl); // Navigate to the page
        window.location.reload(); // Trigger reload after navigation
    };

    return (
        <>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}
            <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content theme-bg-color">
                        <div className="modal-header">
                            <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Upgrade to <span>{liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</span> video pack by just paying the difference </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body rent_video_page_ul_li">
                            <div className="row">
                                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                                <div className='row col-lg-12'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <img alt={liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name} src={liveDetailsData?.Thumbnail} className='w-100 h-100' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                                        <div className='p-2'>
                                            <p className='fw-bold fs-5  theme-text-color'>BUY {liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name}</p>

                                            <hr></hr>

                                            <div className='mt-4'>
                                                <div>
                                                    <img alt={liveDetailsData?.title ? liveDetailsData.title : liveDetailsData?.name} src={liveDetailsData?.Player_thumbnail} className='w-50' />
                                                </div>
                                                <div className='mt-3'>
                                                    <ul>
                                                        <li className=' theme-text-color'>
                                                            Available to watch for <span className='fw-bold'>30 Days</span> after renting
                                                        </li>
                                                        <li className=' theme-text-color'>
                                                            Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className='mt-2'>
                                                <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                                                    {paymentGateways?.map((gateway, index) => (
                                                        <li
                                                            className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                                                            role="presentation"
                                                            key={index}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="nav-link"
                                                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                                name="tab-radio"
                                                                value={gateway?.Payment_Gateway_name}
                                                                checked={activeGateway === gateway?.Payment_Gateway_name}
                                                                onChange={() =>
                                                                    handleGatewayClick(gateway?.Payment_Gateway_name)
                                                                }
                                                            />
                                                            <label
                                                                className="ms-2 theme-text-color"
                                                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                            >
                                                                {gateway?.Payment_Gateway_name}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className='mt-2'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                                                        <div className="tab-content text-start" id="pills-tabContent">
                                                            {paymentGateways?.map((gateway, index) => (
                                                                <div
                                                                    className={`tab-pane theme-text-color ${activeGateway === gateway?.Payment_Gateway_name
                                                                        ? "show active"
                                                                        : "fade"
                                                                        }`}
                                                                    id={`pills-${gateway?.Payment_Gateway_name}`}
                                                                    role="tabpanel"
                                                                    key={index}
                                                                >
                                                                    <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}

            <div className='paddingTopone'>
                {error ? <Error404 /> : isLoading ? <PageLoader /> :
                    liveDetailsData !== null ?
                        <>
                            <HelmetDetails metaURL={currentPageURL} metaTitle={liveDetailsData?.title ? liveDetailsData.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} metaDescription={liveDetailsData?.description?.toString().substring(0, 100)} metaImage={liveDetailsData?.Player_thumbnail} pageTitle={liveDetailsData?.title ? liveDetailsData.title?.toUpperCase() : liveDetailsData?.name?.toUpperCase()} />
                            <div style={{ backgroundImage: `url(${liveDetailsData?.Player_thumbnail})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: "fixed" }} className='px-md-5 px-3 py-md-3 py-3 linearShadow w-100 videoDetailsWrapper '>
                                <div className='position-relative'>
                                    <Breadcrumb bread1={t("Home")} bread2={t("Live")} bread3={t(liveDetailsData?.title)} link1="/" link2={navigateTranslateChecker(`/live-categories`)} slase />
                                    <div className='pageWrapper'>
                                        <div className="col-12 col-lg-10">
                                            <h3 className="midHeading2 mb-3 text-break">{t(liveDetailsData?.title)} {t(liveDetailsData?.name)} </h3>

                                            {/* {liveDetailsData?.year ?
                                        <div className="overview">
                                            <span  className="dot theme-button-bg-color me-2"></span><label className='smallText1'>{t(liveDetailsData?.year)}</label>
                                            {liveDetailsData?.duration_format ? <> <span  className="dot theme-button-bg-color mx-2"></span><label className='smallText1'>{t(liveDetailsData?.duration_format)}</label></> : ""}

                                        </div> : ""} */}
                                            {/* {liveDetailsData?.created_at ?
                                        <div className="overview">
                                            <MessageBox classname='theme-text-color d-inline-block me-2 mb-3 detailsList' text={t("Publish Date")} />
                                            <label className='smallText1'>{t(formattedDate)}</label>
                                        </div> : ""} */}

                                            {/* {liveDetailsData?.duration?
                                        <div className="overview">
                                            <MessageBox classname='theme-text-color d-inline-block me-2 mb-2 detailsList' text={t("Duration")} />
                                            <label className='smallText1'>{t(liveDetailsData?.duration)}</label>
                                        </div> : ""} */}

                                            {liveDetailsData?.description ? <MessageBox text={liveDetailsData?.description} classname={`descript d-block text-wrap ${expanded ? 'h-auto' : liveDetailsData?.description.length > 200 ? 'hide' : ''}`} /> : null}
                                            {liveDetailsData?.description.length > 200 ? <button className='bg-transparent p-0' onClick={() => setExpanded(!expanded)}><MessageBox text={expanded ? 'Show Less' : 'Show More'} classname='theme-text-color descript mt-2 d-block' /></button> : null}


                                            {liveDetailsData?.created_at || liveDetailsData?.duration_format ? <div className='d-flex align-items-center flex-wrap my-4'>
                                                {liveDetailsData?.created_at ? <label className='smallText1 beforeCircle text-break'>{t(formattedDate)}</label> : ""}
                                                {liveDetailsData?.duration ? <label className='smallText1 ms-4 text-break'>{t(liveDetailsData?.duration_format)}</label> : ""}
                                            </div> : null}

                                            {liveDetailsData?.Language?.length > 0 ?
                                                <div className='d-flex align-items-center flex-wrap'>
                                                    {liveDetailsData?.Language?.map((item, index) => (
                                                        <Link to={navigateTranslateChecker(`/languages/${item?.slug}`)} className={`position-relative smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3 me-4 beforeCircle`}>{t(item?.name)}</Link>
                                                    ))}
                                                </div> : ''}



                                            {liveDetailsData?.categories || liveDetailsData?.artists ? <div className='d-flex align-items-center flex-wrap '>
                                                {liveDetailsData?.categories?.length > 0 ? liveDetailsData?.categories?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/live-categories/${item?.slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3  me-4 beforeCircle `}>{t(item?.name)}</Link>
                                                )) : null}
                                                {liveDetailsData?.artists?.length > 0 ? liveDetailsData?.artists?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/artists/${item.artist_slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 mb-3 text-break me-4 beforeCircle`}>{t(item?.artist_name)}</Link>
                                                )) : null}
                                            </div> : null}


                                            {/* {liveDetailsData?.categories.length > 0 && liveDetailsData?.artists.length > 0 ?
                                            <div className='d-flex align-items-center mb-2'>
                                                <div className="d-flex justify-content-between">
                                                    {liveDetailsData?.categories?.map((item, index) => (
                                                        <>
                                                            {index > 0 && <span className="dot mt-3 me-2"></span>}
                                                            <button className='backgroundLang theme-bg-category-color me-2 mr-2'>
                                                                <Link to={navigateTranslateChecker(`/video-categories/${item?.slug}`)} className='smallText1 theme-text-color-list'>
                                                                    {t(item?.name)}
                                                                </Link></button>
                                                        </>
                                                    ))}
                                                    {liveDetailsData?.artists.map((item, index) => (
                                                        <>
                                                            
                                                            <span className="dot mt-3 me-2"></span>
                                                            <button key={item.artist_slug} className='backgroundLang theme-bg-category-color me-2 mr-2'>
                                                                <Link to={navigateTranslateChecker(`/video-categories/${item.artist_slug}`)} className='smallText1 theme-text-color-list'>
                                                                    {t(item.artist_name)}
                                                                </Link>
                                                            </button>
                                                        </>
                                                    ))}
                                                </div>
                                            </div> : ''} */}
                                            {/* {liveDetailsData?.categories.length > 0 ?
                                        <div className='d-flex align-items-center mb-2' >
                                            <div className="d-flex flex-wrap">
                                                {liveDetailsData?.categories?.map((item) => (
                                                    <a href={navigateTranslateChecker(`/video-categories/${item?.slug}`)} className='smallText1 beforeLine theme-text-color-list me-4'>
                                                        {t(item?.name)}
                                                    </a>
                                                ))}
                                            </div>
                                        </div> : ''} */}

                                            {/* {liveDetailsData?.categories.length > 0 ?
                                        <div className='d-flex align-items-center mb-2'>
                                            <MessageBox classname='theme-text-color d-inline-block me-2 detailsList' text={t("Live Category")} />
                                            <div className="d-flex justify-content-between">
                                                {liveDetailsData?.categories?.map((item) => (
                                                    <button className='backgroundLang me-2 mr-2'>
                                                        <Link to={navigateTranslateChecker(`/video-categories/${item?.slug}`)} className='smallText1 theme-text-color-list'>
                                                            {t(item?.name)}
                                                        </Link></button>
                                                ))}
                                            </div>
                                        </div> : ''} */}


                                            {/* {liveDetailsData?.artists.length > 0 ?
                                        <div className='d-flex align-items-center mb-2' >
                                            <div className="d-flex flex-wrap">
                                                {liveDetailsData?.artists?.map((item) => (
                                                    <a href={navigateTranslateChecker(`/video-categories/${item?.artist_slug}`)} className='smallText1 beforeLine theme-text-color-list me-4'>
                                                        {t(item?.artist_name)}
                                                    </a>
                                                ))}
                                            </div>
                                        </div> : ''} */}
                                            {/* {liveDetailsData?.Language?.length > 0 ?
                                        <div className='d-flex align-items-center mb-2'>
                                           <div className="d-flex justify-content-between">
                                                {liveDetailsData?.Language?.map((item) => (
                                                    <button className='backgroundLang me-2 mr-2'>
                                                        <Link to={navigateTranslateChecker(`/languages/${item?.slug}`)} className='smallText1 theme-text-color-list'>
                                                            {t(item?.name)}
                                                        </Link></button>
                                                ))}
                                            </div>
                                        </div> : ''} */}
                                            {/* {liveDetailsData?.artists.length > 0 ?
                                        <div className='d-flex align-items-center mb-2'>
                                            <MessageBox classname='theme-text-color d-inline-block me-2 detailsList' text={t("Trainer")} />
                                            <div className="d-flex justify-content-between">
                                                {liveDetailsData?.artists?.map((item) => (
                                                    <button className='backgroundLang me-2 mr-2'>
                                                        <Link to={navigateTranslateChecker(`/video-categories/${item?.artist_slug}`)} className='smallText1 theme-text-color-list'>
                                                            {t(item?.artist_name)}
                                                        </Link></button>
                                                ))}
                                            </div>
                                        </div> : ''} */}


                                            <div className='d-flex flex-wrap gap-3'>
                                                {/* New condition for scheduled livestream */}
                                                {liveDetailsData?.publish_type === "publish_later" && liveDetailsData?.livestream_publish_status ? (
                                                    <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block theme-button-text-color">
                                                        {t("Publish on")} {liveDetailsData?.livestream_publish_status}
                                                    </button>
                                                ) : (
                                                    /* Existing conditions */
                                                    !user_role && liveDetailsData?.users_free_duration_status === true
                                                        && liveDetailsData?.users_livestream_visibility_status === true
                                                        && liveDetailsData?.users_livestream_visibility_Rent_button === false
                                                        && liveDetailsData?.users_livestream_visibility_becomesubscriber === false
                                                        && liveDetailsData?.users_livestream_visibility_register_button === true
                                                        ? <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color' to={navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`)}>{t(`Free for start ${totalSeconds} sec`)}</Link>
                                                        : liveDetailsData?.users_livestream_visibility_status == true &&
                                                            liveDetailsData?.continue_watching_exists &&
                                                            liveDetailsData?.users_free_duration_status === false ?
                                                            <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color'
                                                                to={navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`)}><span>{t("Continue Watching")}</span></Link>
                                                            : liveDetailsData?.users_livestream_visibility_status == true &&
                                                                liveDetailsData?.continue_watching_exists == null &&
                                                                liveDetailsData?.users_free_duration_status === false ?
                                                                <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color'
                                                                    onClick={handleReload}><span>{t("Watch Now")}</span> </Link>
                                                                : liveDetailsData?.users_livestream_visibility_status == true
                                                                    && liveDetailsData?.users_livestream_visibility_Rent_button == true
                                                                    && liveDetailsData?.users_free_duration_status === true
                                                                    ? <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color' to={navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`)}>{t(`Free for start ${totalSeconds} sec`)}</Link>
                                                                    : liveDetailsData?.users_livestream_visibility_status == false
                                                                        && liveDetailsData?.users_livestream_visibility_Rent_button == true
                                                                        && liveDetailsData?.users_livestream_visibility_becomesubscriber == true
                                                                        ? <>
                                                                            <button
                                                                                className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                                                                type="button"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#staticBackdrop"
                                                                            >
                                                                                {(() => {
                                                                                    if (!currency?.code) {
                                                                                        return `Rent Now ${currency?.symbol} ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                            ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                            : "Loading..."
                                                                                            }`;
                                                                                    }

                                                                                    if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                                                                                        return `Rent Now  ${currency?.symbol}  ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                            ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                            : "Loading..."
                                                                                            }`;
                                                                                    }

                                                                                    if (liveDetailsData?.ContentPriceRate !== null) {
                                                                                        return `Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice !== null && !isNaN(liveDetailsData?.ContentPriceRate?.ChangedPrice)
                                                                                            ? Number(liveDetailsData.ContentPriceRate.ChangedPrice).toFixed(2)
                                                                                            : "Loading..."
                                                                                            }`;
                                                                                    }

                                                                                    return `Rent Now ${currencySymbol} ${convertedPrice !== null && !isNaN(convertedPrice) ? Number(convertedPrice).toFixed(2) : "Loading..."
                                                                                        }`;
                                                                                })()}
                                                                            </button>
                                                                            <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className='theme-button-text-color' to={navigateTranslateChecker(`/plan`)}>{t("Become a Subscriber")}</Link>  </button>
                                                                        </>
                                                                        : user_role == 2 && liveDetailsData?.users_free_duration_status == true &&
                                                                            liveDetailsData?.users_livestream_visibility_status == false &&
                                                                            liveDetailsData?.users_livestream_visibility_Rent_button == true &&
                                                                            liveDetailsData?.users_livestream_visibility_becomesubscriber == true
                                                                            ? <>
                                                                                <button
                                                                                    className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                                                                    type="button"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#staticBackdrop"
                                                                                >
                                                                                    {(() => {
                                                                                        if (!currency?.code) {
                                                                                            return `Rent Now ${currency?.symbol} ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                : "Loading..."
                                                                                                }`;
                                                                                        }

                                                                                        if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                                                                                            return `Rent Now  ${currency?.symbol}  ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                : "Loading..."
                                                                                                }`;
                                                                                        }

                                                                                        if (liveDetailsData?.ContentPriceRate !== null) {
                                                                                            return `Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice !== null && !isNaN(liveDetailsData?.ContentPriceRate?.ChangedPrice)
                                                                                                ? Number(liveDetailsData.ContentPriceRate.ChangedPrice).toFixed(2)
                                                                                                : "Loading..."
                                                                                                }`;
                                                                                        }
                                                                                        return `Rent Now ${currencySymbol} ${convertedPrice !== null && !isNaN(convertedPrice) ? Number(convertedPrice).toFixed(2) : "Loading..."
                                                                                            }`;
                                                                                    })()}
                                                                                </button>
                                                                                <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className='theme-button-text-color' to={navigateTranslateChecker(`/plan`)}>{t("Become a Subscriber")}</Link>  </button>
                                                                            </>
                                                                            : user_role === 3 && liveDetailsData?.users_free_duration_status == true &&
                                                                                liveDetailsData?.users_livestream_visibility_status == false &&
                                                                                liveDetailsData?.users_livestream_visibility_Rent_button == true &&
                                                                                liveDetailsData?.users_livestream_visibility_becomesubscriber == true
                                                                                ? (
                                                                                    settings?.enable_ppv_rent == 1
                                                                                        ? <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color'
                                                                                            to={navigateTranslateChecker(`/player/live/${liveDetailsData?.slug}`)}><span>{t("Watch Now")}</span> </Link>
                                                                                        : <button
                                                                                            className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                                                                            type="button"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target="#staticBackdrop"
                                                                                        >
                                                                                            {(() => {
                                                                                                if (!currency?.code) {
                                                                                                    return `Rent Now ${currency?.symbol} ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                        ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                        : "Loading..."
                                                                                                        }`;
                                                                                                }

                                                                                                if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                                                                                                    return `Rent Now  ${currency?.symbol}  ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                        ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                        : "Loading..."
                                                                                                        }`;
                                                                                                }

                                                                                                if (liveDetailsData?.ContentPriceRate !== null) {
                                                                                                    return `Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice !== null && !isNaN(liveDetailsData?.ContentPriceRate?.ChangedPrice)
                                                                                                        ? Number(liveDetailsData.ContentPriceRate.ChangedPrice).toFixed(2)
                                                                                                        : "Loading..."
                                                                                                        }`;
                                                                                                }

                                                                                                return `Rent Now ${currencySymbol} ${convertedPrice !== null && !isNaN(convertedPrice) ? Number(convertedPrice).toFixed(2) : "Loading..."
                                                                                                    }`;
                                                                                            })()}
                                                                                        </button>
                                                                                )
                                                                                : liveDetailsData?.users_livestream_visibility_status == false &&
                                                                                    liveDetailsData?.users_livestream_visibility_Rent_button == true &&
                                                                                    liveDetailsData?.users_free_duration_status === true
                                                                                    ? <button
                                                                                        className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                                                                        type="button"
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#staticBackdrop"
                                                                                    >
                                                                                        {(() => {
                                                                                            if (!currency?.code) {
                                                                                                return `Rent Now ${currency?.symbol} ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                    ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                    : "Loading..."
                                                                                                    }`;
                                                                                            }

                                                                                            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                                                                                                return `Rent Now  ${currency?.symbol}  ${liveDetailsData?.ppv_price !== null && !isNaN(liveDetailsData?.ppv_price)
                                                                                                    ? Number(liveDetailsData.ppv_price).toFixed(2)
                                                                                                    : "Loading..."
                                                                                                    }`;
                                                                                            }

                                                                                            if (liveDetailsData?.ContentPriceRate !== null) {
                                                                                                return `Rent Now ${liveDetailsData?.ContentPriceRate?.Symbol} ${liveDetailsData?.ContentPriceRate?.ChangedPrice !== null && !isNaN(liveDetailsData?.ContentPriceRate?.ChangedPrice)
                                                                                                    ? Number(liveDetailsData.ContentPriceRate.ChangedPrice).toFixed(2)
                                                                                                    : "Loading..."
                                                                                                    }`;
                                                                                            }

                                                                                            return `Rent Now ${currencySymbol} ${convertedPrice !== null && !isNaN(convertedPrice) ? Number(convertedPrice).toFixed(2) : "Loading..."
                                                                                                }`;
                                                                                        })()}
                                                                                    </button>
                                                                                    : liveDetailsData?.users_livestream_visibility_status == false &&
                                                                                        liveDetailsData?.users_livestream_visibility_register_button == true
                                                                                        ? <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"> <Link className='theme-button-text-color' to={navigateTranslateChecker(`/signup`)}><span>{t("Register Now")}</span> </Link></button>
                                                                                        : liveDetailsData?.users_livestream_visibility_status == false &&
                                                                                            liveDetailsData?.users_livestream_visibility_becomesubscriber == true ?
                                                                                            <button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className='theme-button-text-color' to={navigateTranslateChecker(`/plan`)}><span>{t("Become a Subscriber")}</span></Link>  </button>
                                                                                            : null
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-md-5 px-3 py-3'>
                                <h3 className='tabHeading mb-3 highlightTabColor d-inline position-relative'>{t("More Like this")}</h3>
                                <MoreLike data={liveDetailsData?.related_livestream} segMent={'live'} />
                            </div>

                        </> :
                        <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3>{t("NO DATA FOUND")}</h3></div>
                }
            </div>

        </>
    )
}
const mapStateToProps = state => ({
    liveDetailsData: state.get_liveDetails_Reducer.liveDetailsData,
    isLoading: state.get_liveDetails_Reducer.isLoading,
    error: state.get_liveDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    livePayment_setting: state.get_liveDetails_Reducer.rentpayment_setting,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    settingsall: state.get_setting_Reducer.settingsall,
});
export default connect(mapStateToProps)(Livestreams)