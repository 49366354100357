// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
// Internal Styles

// Internal Components
import SongIcon from "../../../assets/icons/head-set-icon.webp"
import WishList from "../../../assets/icons/wishlist-icon.webp"
import { artistsDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../Error404';
import { useTranslation } from 'react-i18next';
import PageLoader from '../../Loader/PageLoader';
import { IconMyPlay } from '../../../assets/icons/MyIcons';
import Breadcrumb from '../../Breadcrumb';


const ArtistDetails = ({ artistsDetailsData, topArtists, isLoading, settings, currentIndex, currentAudio, currentTime, currentTrackId, currentDuration, currentPlaying, playlistAudio, error }) => {

    const dispatch = useDispatch();
    const { lang, slug } = useParams();
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [product, setProduct] = useState();
    const [ccurrency, setCcurrency] = useState();
    const [activeBtn, setActiveBtn] = useState("none")
    const [likeCount, setLikeCount] = useState(10);
    const [dislikeCount, setDislikeCount] = useState(10);
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");

    const data = artistsDetailsData?.Player_thumbnail;
    const id = artistsDetailsData?.id;


    useEffect(() => {
        dispatch({
            type: "GET_PLAYING_SUCCESS",
            isPlaying: false,
            isClose: false,
        });
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(artistsDetailsAction(null, 9, "web", slug)).then(() => {
                    if (playlistAudio?.length > 0) {
                        dispatch({
                            type: "GET_CURRENT_SUCCESS",
                            payload: playlistAudio[currentIndex == 0 ? 0 : currentIndex],
                            index: currentIndex == 0 ? 0 : currentIndex,
                            trackid: currentAudio?.id,
                            currenttime: currentTime,
                            duration: currentDuration,
                            isPlaying: currentPlaying ? true : false,
                        });
                    }
                })
                : user_role && dispatch(artistsDetailsAction(user_id, user_role, "web", slug)).then(() => {
                    if (playlistAudio?.length > 0) {
                        dispatch({
                            type: "GET_CURRENT_SUCCESS",
                            payload: playlistAudio[currentIndex == 0 ? 0 : currentIndex],
                            index: currentIndex == 0 ? 0 : currentIndex,
                            trackid: currentAudio?.id,
                            currenttime: currentTime,
                            duration: currentDuration,
                            isPlaying: currentPlaying ? true : false,
                        });
                    }
                })
        }
    }, [settings, user_role])

    const handleCurrentAudioPlay = (audiolist, index) => {

        // dispatch({
        //     type: "GET_PLAYING_SUCCESS",
        //     isPlaying: true,
        //     isClose: true,
        // });
    };



    const handleToken = (token) => {
        console.log(token);
        const body = { token, product, name: product?.title, id: product?.id, price: product?.price, product_by: product?.product_by, email: token?.email, token: token?.id, };
        const headers = {
            "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json", "Content-Type": "application/json", "Access-Control-Allow-Headers":
                "Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "X-Requested-With": "*",
        }; fetch(`${process.env.REACT_APP_Baseurl}/admin/storessssssas`, {
            method: "POST", headers: headers, body: JSON.stringify(body),
        }).then((resp) => {
            if (!resp.ok) { throw new Error("API request failed"); } return resp.json();
        }).then((data) => { toast.success(t("Payment Updated Successfully")); setTimeout(() => { window.location.reload(); }, 3500); }).catch((err) => {
            console.error(err); toast.error(t("Payment Update Failed")); setTimeout(() => { window.location.reload(); }, 3500)
        });
    };

    //  ****     
    const handleLikeClick = () => {
        axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Like-Update`, {
            Login_user_type: user_role,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_id: id,
            source_type: 1,
            like_status: 1,
        })
            .then((response) => {
                if (response?.data?.status == true) {
                    toast.success(t(response?.data?.message));
                }
            })
            .catch((error) => {
                console.error(error);
            });
        if (activeBtn == "none") {
            setLikeCount(likeCount + 1);
            setActiveBtn("like");
            return;
        }

        if (activeBtn == "like") {
            setLikeCount(likeCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn == "dislike") {
            setLikeCount(likeCount + 1);
            setDislikeCount(dislikeCount - 1);
            setActiveBtn("like");
        }
    };

    const handleDisikeClick = () => {
        axios
            .post(`${process.env.REACT_APP_Baseurl}/Front-End/Dislike-Update`, {
                Login_user_type: user_role,
                Login_user_id: user_id,
                Login_device_type: "web",
                source_id: id,
                source_type: 1, // For Videos
                dislike_status: 1,
            }) // Assuming the API supports PUT for updates
            .then((response) => {
                toast.error(t(response?.data?.message));
            })
            .catch((error) => {
                console.log(error);
            });
        if (activeBtn == "none") {
            setDislikeCount(dislikeCount + 1);
            setActiveBtn("dislike");
            return;
        }

        if (activeBtn == "dislike") {
            setDislikeCount(dislikeCount - 1);
            setActiveBtn("none");
            return;
        }

        if (activeBtn == "like") {
            setDislikeCount(dislikeCount + 1);
            setLikeCount(likeCount - 1);
            setActiveBtn("dislike");
        }
    };
    const [buttonText, setButtonText] = useState("Add To WishList");

    function handleClickWishList() {
        axios
            .post(`${process.env.REACT_APP_Baseurl}/Front-End/Wishlist-Update`, {
                Login_user_type: user_role,
                Login_user_id: user_id,
                Login_device_type: "web",
                source_id: id,
                source_type: 1,
            })
            .then((response) => {
                if (response?.data?.status == true) {
                    toast.success(t("Completed"));
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setButtonText(
            buttonText == "Add To WishList" ? "Remove To WishList" : "Add To WishList"
        );
    }

    function handleClickWatchLater() {
        axios
            .post(`${process.env.REACT_APP_Baseurl}/Front-End/Watchlater-Update`, {
                Login_user_type: user_role,
                Login_user_id: user_id,
                Login_device_type: "web",
                source_id: id,
                source_type: 1,
            })
            .then((response) => {
                if (response?.data?.status == true) {
                    //console.log(response.data);
                    toast.success(t(response?.data?.message));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }






    const [activeTab, setActiveTab] = useState(null); // Tracks the current tab
    const [tabData, setTabData] = useState([]); // Tracks the data to display in the active tab

    // Filter out empty arrays from artistDetails
    const availableTabs = Object.keys(artistsDetailsData).filter(
        (key) => artistsDetailsData[key] && artistsDetailsData[key].length > 0
    );

    // Load initial data for the first available tab
    useEffect(() => {
        if (!activeTab && availableTabs.length > 0) {
            const initialTab = availableTabs[0];
            setActiveTab(initialTab);
            setTabData(artistsDetailsData[initialTab]); // Set data for the first tab
        }
    }, [activeTab, availableTabs, artistsDetailsData]);

    // Handle tab click to fetch or process data for the selected tab
    const handleTabClick = (tabKey) => {
        if (artistsDetailsData[tabKey] && artistsDetailsData[tabKey].length > 0) {
            setActiveTab(tabKey);
            setTabData(artistsDetailsData[tabKey]);
        }
    };



    return (
        // <div>
        //     {isLoading ? <div className='d-flex justify-content-center align-items-center paddingTop'>
        //         <PageLoader/>
        //         : <div className='paddingTop'>
        //             <section className="rent_payment_setting">
        //                 <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        //                     <div className="modal-dialog">
        //                         <div className="modal-content">
        //                             <div className="modal-header">
        //                                 <h5 className="modal-title rent_policsh_color" id="staticBackdropLabel">Rent Now </h5>
        //                                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
        //                             <div className="modal-body">
        //                                 <div className="col-lg-12 row">
        //                                     <div className="col-lg-5 p-0">
        //                                         <img className="w-100" src={product?.Thumbnail} /></div>
        //                                     <div className="col-lg-7"><div>
        //                                         <div>
        //                                             <h4 className="rent_policsh_color">{product?.title}</h4>
        //                                         </div>
        //                                         <div>
        //                                             <p className="rent_policsh_color mt-3">Amount {ccurrency?.symbol} {product?.ppv_price}</p>
        //                                         </div>
        //                                     </div>
        //                                         <div className="text-start mt-5">
        //                                             <div>
        //                                                 <h4 className="rent_policsh_color">Payment Method</h4>
        //                                             </div>
        //                                             <div>
        //                                                 <p className="rent_policsh">POLICIES: Pay with your only this video to show </p>
        //                                             </div>
        //                                         </div>
        //                                         <div className="d-flex align-items-start mt-3 label_home_radio_text">
        //                                             <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical"><div className="" style={{ display: "flex" }}><input type="radio" className="nav-link" id="v-pills-home-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" /><label htmlFor="v-pills-home-tab" className="nav-link ">Pay with Credit or Debit Card</label></div><div className="d-flex"><input type="radio" className="nav-link" id="v-pills-profile-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" /><label htmlFor="v-pills-profile-tab" className="nav-link">Pay with Paypal</label></div><div className="d-flex"><input type="radio" className="nav-link" id="v-pills-messages-tab" name="v-pills-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" /><label htmlFor="v-pills-messages-tab" className="nav-link"> Paystack</label></div></div></div></div></div></div><div className="modal-footer"><div className="tab-content" id="v-pills-tabContent">
        //                                                 <div className="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        //                                                     <StripeCheckout name={product?.title} amount={product?.ppv_price * 100} currency={ccurrency?.symbol} token={handleToken} stripeKey="pk_test_51M4ja8SJZVJvQCKRasiMCPmmb0vjs31HU2Brf1RUKxr4ZHzjOkngy4FdbvLZX0ap0rwU1S7w0X6CFgAEnlEbjT9F00xE9neHhR">
        //                                                         <button className="btn btn-primary">Continue to Purchase</button>
        //                                                     </StripeCheckout></div>
        //                                                 <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
        //                                                     <button> Continue to Purchase</button>
        //                                                 </div>
        //                                                 <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
        //                                                     <button> Continue to Purchase</button>
        //                                                 </div>
        //                                             </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 {artistsDetailsData !== null ?
        //                     <div style={{ backgroundImage: `url(${data})`, backgroundPosition: 'center', backgroundSize: 'cover', width: '100%', height: '100%', }} className='px-md-5 px-2 py-md-3 py-1 linearShadow'>
        //                         <div className='position-relative'>
        //                             <Breadcrumb bread1="Home" bread2="Videos" bread3={artistsDetailsData?.title} link1="/" link2={`/videos/${source}`} link3={""} slase />
        //                             <div className='pageWrapper '>
        //                                 <div className="d-flex aligns-items-center w-100 flex-wrap">
        //                                     <div className="col-12 col-lg-6 col-md-6">
        //                                         <h3 className="midHeading">
        //                                             {artistsDetailsData?.title?.length > 17 ? artistsDetailsData?.title?.substring(0, 12) : artistsDetailsData?.title}
        //                                             {artistsDetailsData?.name?.length > 17 ? artistsDetailsData?.name?.substring(0, 12) : artistsDetailsData?.name}
        //                                         </h3>
        //                                         <div className="d-flex gap-4 flex-wrap">
        //                                             {artistsDetailsData?.categories?.map((item) => (<span className='smallText beforeCircle' >{item.name}</span>))}
        //                                             {artistsDetailsData?.Language?.map((item) => (<span className='smallText beforeCircle'>{item.name}</span>))}
        //                                             {artistsDetailsData?.artists?.map((item) => (<span className='smallText beforeCircle'>{item.artist_name}</span>))}
        //                                         </div>
        //                                         <span className='smallText'>{artistsDetailsData?.duration_format}</span>
        //                                         <div className="overview">
        //                                             <div className='expendedHeight'>
        //                                                 {
        //                                                     artistsDetailsData?.description?.length > 200 ? (
        //                                                         <p className='smallText pt-4'>
        //                                                             {expanded ? (
        //                                                                 <div className="expandable">
        //                                                                     <p>{artistsDetailsData?.description.toString().substring(0, artistsDetailsData?.description?.length)}</p>
        //                                                                 </div>
        //                                                             ) : (
        //                                                                 <>{artistsDetailsData?.description.toString().substring(0, 200)}</>
        //                                                             )}
        //                                                         </p>
        //                                                     ) : (
        //                                                         <p>{artistsDetailsData?.description}</p>
        //                                                     )
        //                                                 }
        //                                             </div>

        //                                             <span onClick={() => setExpanded(!expanded)} className="smallText extraBold  d-block"> {expanded ? ' Show Less' : ' Show More'} </span>

        //                                             <div className="d-inline-flex align-items-center gap-4 pt-3">
        //                                                 <FaShareAlt className="defaultIconStyle" />
        //                                                 <FiThumbsUp className={`defaultIconStyle ${activeBtn == "like" ? "like-active" : ""}`} onClick={handleLikeClick} />
        //                                                 <FiThumbsDown className={`defaultIconStyle ${activeBtn == "dislike" ? "dislike-active" : ""}`} onClick={handleDisikeClick} />
        //                                                 <FaPlus className={`defaultIconStyle`} onClick={handleClickWatchLater} />
        //                                             </div>
        //                                             <div className="pt-4 d-flex flex-wrap gap-md-3 gap-1 justify-content-center justify-content-md-start ">
        //                                                 <Link to={"/Video-Player"} state={artistsDetailsData} className='d-inline-block'>
        //                                                     <button className="play-btn" type="button">Play Now</button>
        //                                                 </Link>
        //                                                 <button className="add-btn" type="button" onClick={handleClickWishList}>{buttonText}</button>
        //                                                 <button className="play-btn" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Rent Now</button>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                     <div className="col-12 col-lg-6 col-md-6 d-none d-md-block">
        //                                         <img src={data} alt={artistsDetailsData?.slug} className="detailsSideImage lazyload d-block mx-auto" loading="lazy" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     : <div className="py-5 text-center">
        //                         <BiError style={{ fontSize: "50px" }} />
        //                         <h3> NO DATA FOUND</h3>
        //                     </div>}
        //             </section>
        //         </div >
        //     }
        // </div >
        error ? <Error404 /> : <div>
            {isLoading ? <PageLoader />
                : <div className='paddingTop '>
                    <div className="mx-5">
                        <Breadcrumb bread1="Trainer" bread2={artistsDetailsData?.artist?.artist_name} link2={`/artists/${artistsDetailsData?.artist?.artist_slug}/`} link3={""} slase color />
                    </div>

                    <div
                        className='py-md-3 py-2 mx-5'>
                        <div className='col-lg-12 row'>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6'><img className='w-100 ' alt={artistsDetailsData?.artist?.artist_name} src={artistsDetailsData?.artist?.Thumbnail} />
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-6'>

                                <div className='col-lg-12 row'>
                                    <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                        <Link>Artist Name       : </Link> {" "}
                                    </div>
                                    <div className='col-8 col-sm-8 col-md-8 col-lg-8'>
                                        <Link>{artistsDetailsData?.artist?.artist_name}</Link>
                                    </div>
                                </div>

                                <div className='col-lg-12 row'>
                                    <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                        <Link>Artist Type : </Link> {" "}
                                    </div>
                                    <div className='col-8 col-sm-8 col-md-8 col-lg-8'>
                                        <Link>{artistsDetailsData?.artist?.artist_type}</Link>
                                    </div>
                                </div>


                                <div className='col-lg-12 row'>
                                    <div className='col-4 col-sm-4 col-md-4 col-lg-4'>
                                        <Link>Artist Description : </Link> {" "}
                                    </div>
                                    <div className='col-8 col-sm-8 col-md-8 col-lg-8'>
                                        <Link>
                                            {artistsDetailsData?.artist?.description
                                                ? artistsDetailsData.artist.description
                                                : "No description available"}
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="mt-3">
                            {/* Tab Buttons */}
                            <ul className="nav nav-tabs" id="artistTab" role="tablist">
                                {availableTabs.map((key) => (
                                    <li className="nav-item" role="presentation" key={key}>
                                        <li
                                            className={`nav-link highlightTabColor theme-text-color bg-transparent ${activeTab === key ? 'active highlightTabColor theme-text-color  theme-button-tab-color bg-transparent' : ''}`}
                                            id={`${key}-tab`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#${key}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={key}
                                            aria-selected={activeTab === key}
                                            onClick={() => handleTabClick(key)}
                                        >
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </li>
                                    </li>
                                ))}
                            </ul>

                            {/* Tab Content */}
                            <div className="tab-content" id="artistTabContent">
                                {availableTabs.map((key) => (
                                    <div
                                        key={key}
                                        className={`tab-pane fade ${activeTab === key ? "show active" : ""}`}
                                        id={key}
                                        role="tabpanel"
                                        aria-labelledby={`${key}-tab`}
                                    >


                                        {tabData && tabData.length > 0 ? (
                                            tabData?.map((item) => {
                                                // Set the path based on the current tab
                                                let path = '';
                                                if (key === 'videos') {
                                                    path = `/${lang}/videos/${item.slug}`;
                                                }
                                                else if (key === 'series') {
                                                    path = `/${lang}/shows/${item.slug}`;
                                                }
                                                else if (key === 'Livestream') {
                                                    path = `/${lang}/livestream/${item.slug}`;
                                                }
                                                else if (key === 'Audios') {
                                                    path = `/${lang}/music/track/${item.slug}`;
                                                }
                                                return (
                                                    <>
                                                        <Link
                                                            className="text-decoration-none theme-text-color d-block"
                                                            to={path}
                                                            key={item.slug}
                                                        >
                                                            <div
                                                                className="row row-custom my-4 align-items-center episodeContainer"
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <div className="col-12 col-lg-3 mb-2 mb-lg-0">
                                                                    <div className="episodeImageContainer position-relative">
                                                                        <img
                                                                            src={item?.image_url}
                                                                            className="loaderMain episodeImageD w-100 p-3"
                                                                            alt={item?.title} />
                                                                        <div className="playIconContainer"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-lg-9 d-flex flex-column justify-content-start p-3"
                                                                    style={{ borderRadius: '8px', minHeight: '165px' }}>


                                                                    <h3 className="episodeHeading">{item?.title}</h3>
                                                                    <div className="d-flex gap-2 my-2">

                                                                        <div className="overview">
                                                                            <span className="dot me-2"></span>
                                                                            <label className="smallText1">{item?.duration}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div dangerouslySetInnerHTML={{
                                                                        __html: item?.description && item.description.length > 100
                                                                            ? item.description.substring(0, 100) + '...'
                                                                            : item.description || ''
                                                                    }} />
                                                                    <div />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </>

                                                );
                                            })
                                        ) : (
                                            <p>No Related Videos Available</p>
                                        )}




                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    {/* <div
                        className='audioBannerdd px-3  px-lg-5  py-3  py-lg-5 d-flex align-items-center rounded-0'
                    style={{ backgroundImage: `linear-gradient(90deg, #011329 50%, rgba(0, 0, 0, 0)), url(${artistsDetailsData?.Thumbnail})`, backgroundSize: "cover", backgroundPosition: "right", backgroundOrigin: "border-box" }} 
                    >
                        <div className='position-relative audioBannerWidth'>
                            <h1 className='audioBannerHeading theme-text-color '>{artistsDetailsData?.artist_name}</h1>
                            <p className='theme-text-color audioBannerDesc'>{artistsDetailsData?.description}</p>
                            <div className='d-flex  mt-3'>
                                <img src={SongIcon} className="me-3" alt='Totallistener ' />
                                <span className='theme-text-color me-2'>{artistsDetailsData?.views ? artistsDetailsData?.views : "32,23,33"}</span>
                                <span className='opacityColor'>{t("monthly listeners")}</span>
                            </div>
                            <div className='d-flex mt-4 align-items-center'>
                                <Link to={`/music/${slug}`} className='audioBannerPlay me-3 me-md-4 d-flex align-items-center justify-content-center'>
                                    <IconMyPlay styled={{ width: "20px", height: "20px", className: "musicIconHome triangle" }} />
                                </Link>
                                <img src={WishList} alt='wishlistIcon ' className='audioWishList me-2' />
                                <span className='theme-text-color audioText fw-bold'>{t("Wish List")}</span>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className='topArtist py-3 px-4 mt-3 audioBorder'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <p className='audioArtists theme-text-color mb-3'>Top Artists</p>
                            <p className='audioArtists theme-text-color viewall-homepage mb-3  mt-0'>View all  <FaArrowRight /></p>
                        </div>

                        <div className='d-flex aligns-items-center gap-2 topArtistsContainer'>
                            {topArtists?.slice(0, 10).map((artist) => {
                                return (
                                    <div className='audioBorder  audioArtistCard p-2'>
                                        <img src={artist.Thumbnail} alt='artistImage' className='artistImage' />
                                        <span className='artistsCardTitle theme-text-color mt-2'> {artist?.artist_name.slice(0, 10)}{artist.artist_name.length > 10 && ".."}</span>
                                        <span className='artistsCardTitle theme-text-color mt-2 opacityColor'> {artist?.artist_type}</span>
                                        <button className='artistPlayButton'>
                                            <IconMyPlay styled={{ width: "20px", height: "20px", className: "musicIconHome triangle" }} />
                                        </button>
                                    </div>
                                )
                            })}
                        </div> 


                    </div> */}
                    {/* <div className='px-2 px-md-3 px-lg-4'>
                        <div className=' listenCard mt-3 '>
                            <div className='d-flex justify-content-between align-items-center px-4 py-2  '>
                                <p className='audioArtists theme-text-color'>{t("Popular")} </p>
                                <p className='audioArtists theme-text-color viewall-homepage mb-2 mb-md-3 mt-0'>View all <FaArrowRight /></p>
                            </div>

                            {
                                playlistAudio?.map((audio, index) => {
                                    return (
                                        <div className='d-flex align-items-center justify-content-between px-1 px-md-2 px-lg-4 borderBottom py-2' >
                                            <div className='d-flex align-items-center leftSideListAudio w-75' onClick={() => handleCurrentAudioPlay(audio, index)}>
                                                <div className={` ${currentIndex == index ? "activeAudioBg widthAudio" : "widthAudio"} `}>
                                                    <p className={` ${currentIndex == index ? "d-none widthAudio text-center" : "d-block text-center widthAudio "} `}>{index + 1}</p>
                                                    <img src={'d'} className={` ${currentIndex == index ? "d-block" : "d-none"} playIconForActive`} />
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={audio?.image_url} className='audioImage ms-1 ms-md-2 ms-lg-3 me-1 me-md-2' />
                                                    <p className='audioListText me-2'>{t(audio?.title.slice(0, 10))}</p>
                                                </div>

                                                <div className='d-flex align-items-center d-none d-md-block'>
                                                    <img src={SongIcon} className="me-3 smallIcons" alt='Totallistener ' />
                                                    <span className='theme-text-color me-1 audioViews'>3,45,001</span>
                                                </div>
                                                <div className='d-flex align-items-center'>
                                                    <img src={''} className='ms-2 ms-md-3 ms-lg-4 me-2 smallIcons' />
                                                    <span className='othersTime audioViews'>  {t(audio?.duration)}</span>
                                                </div>

                                            </div>
                                            <div className='w-25'>
                                                <div className='d-flex align-items-center gap-4 justify-content-end'>
                                                    <img src={Like} className='d-none d-md-block' />
                                                    <img src={''} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div> */}
                </div>
            }
        </div>
    )
}
const mapStateToProps = state => ({
    artistsDetailsData: state.get_artistDetails_Reducer.artistsDetailsData,
    error: state.get_artistDetails_Reducer.error,
    topArtists: state.get_artistDetails_Reducer.topArtists,
    artistsAudios: state.get_artistDetails_Reducer.artistsAudios,
    isLoading: state.get_artistDetails_Reducer.isLoading,
    settings: state.get_setting_Reducer.settings,
    currentIndex: state.get_current_Reducers.currentIndex,
    currentAudio: state.get_current_Reducers.currentAudio,
    currentTime: state.get_current_Reducers.currentTime,
    currentTrackId: state.get_current_Reducers.currentTrackId,
    currentDuration: state.get_current_Reducers.currentDuration,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    playlistAudio: state.get_playlist_Reducer.playlistAudio,

});
export default connect(mapStateToProps)(ArtistDetails)
