import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useParams } from 'react-router-dom';
import PriceLabel from './PriceLabel';
import { connect } from 'react-redux';
import CardWithHover from '../Pages/HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../Pages/HomePage/HomeSwiperComponents/Theme/CardWithoutHover';

const MoreLike = ({ data, segMent, themeMode }) => {
    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }
    return (
        <> <div className='pt-3 zIndex'>
            <Swiper {...swiperConfig}>
                {data?.map((value, index) => (
                    <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                        {themeMode?.hoverCard ? <CardWithHover value={value} segMent={segMent} hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={value} segMent={segMent} />}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
        </>)
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(MoreLike);