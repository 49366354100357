// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
// Internal Styles
import { toast } from "react-toastify";
import "../../../Pages/HomePage/Video_details.css"
import "../../../Styles/Details.css"
// Internal Components
import { episodeDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../Error404';
import HelmetDetails from '../../HelmetDetails';
import Breadcrumb from '../../Breadcrumb';
import { LikeAction, disLikeAction, watchLaterAction, wishListAction } from '../../../Pages/CommonMethods/CommonMethods';
import { useTranslation } from 'react-i18next';
import MessageBox from '../../MessageBox';
import usePpvGlobalImageConfig from "../../Api/Rentppv_global_change";
import axios from 'axios';
import { useMyContext } from '../../Layout/CommonProvider';
import PageLoader from '../../Loader/PageLoader';

const Episodes = ({ episodeDetailsData, isLoading, error, settings, profileDetails, settingsall }) => {
    const episodeData = episodeDetailsData?.Episode_details;
    const seriesData = episodeData?.series_details;
    const seasonData = episodeData?.series_season_details;
    const episodePPVExists = episodeDetailsData?.PPV_exists;
    const { lang, series_slug, episode_slug } = useParams();
    const { t } = useTranslation();
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json" };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [domReload, setDomReload] = useState(false)
    const currentPageURL = window.location.href
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const { navigateTranslateChecker } = useMyContext()

    useEffect(() => {
        if (settings && Object.keys(settings).length > 0) {
            !user_role && settings.access_free == 1
                ? dispatch(episodeDetailsAction(null, 9, "web", series_slug, episode_slug, headers))
                : user_role && dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episode_slug, headers));
        }
    }, [settings, user_role, domReload, location])

    const handleDOM = () => {
        setDomReload(!domReload)
    }

    const codeNumber = "4";

    const handleLikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const likeStatus = episodeData?.like == null || episodeData?.like == 0 ? 1 : 0;
            const response = await LikeAction(user_id, userRole, 'web', episodeData?.id, codeNumber, likeStatus);
            handleDOM();
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };
    const handleDislikeClick1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            const likeStatus = episodeData?.dislike == null || episodeData?.dislike == 0 ? 1 : 0;
            const response = await disLikeAction(user_id, userRole, 'web', episodeData?.id, codeNumber, likeStatus);
            handleDOM();
            toast.success(t(response?.message));
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickWishList1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            await wishListAction(user_id, userRole, 'web', episodeData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickWatchLater1 = async () => {
        try {
            let userRole = access_token ? user_role : 9;
            if (userRole === 9) {
                navigate(navigateTranslateChecker(`/login`));
                return;
            }
            await watchLaterAction(user_id, userRole, 'web', episodeData?.id, codeNumber);
            handleDOM();
        } catch (error) {
            console.error(error);
        }
    };


    // const handleLikeClick = async () => {
    //     try {
    //         let userRole = access_token ? user_role : 9;
    //         const likeStatus = episodeData?.like == null || episodeData?.like == 0 ? 1 : 0
    //         await LikeAction(user_id, userRole, 'web', episodeData?.id, codeNumber, likeStatus);
    //         handleDOM();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // const handleDisikeClick = async () => {
    //     try {
    //         let userRole = access_token ? user_role : 9;
    //         const likeStatus = episodeData?.dislike == null || episodeData?.dislike == 0 ? 1 : 0;
    //         await disLikeAction(user_id, userRole, 'web', episodeData?.id, codeNumber, likeStatus);
    //         handleDOM();
    //     } catch (error) {
    //         console.error(error);
    //     }

    // };

    // const handleClickWishList = async () => {
    //     try {
    //         let userRole = access_token ? user_role : 9;
    //         await wishListAction(user_id, userRole, 'web', episodeData?.id, codeNumber);
    //         handleDOM();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // const handleClickWatchLater = async () => {
    //     try {
    //         let userRole = access_token ? user_role : 9;
    //         await watchLaterAction(user_id, userRole, 'web', episodeData?.id, codeNumber);
    //         handleDOM();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }
    // const episodeApii = async (episodeSlug) => {
    //     if (settings && Object.keys(settings).length > 0) {
    //         try {
    //             let actionResult;
    //             if (!user_role && settings.access_free == 1) {
    //                 actionResult = await dispatch(episodeDetailsAction(null, 9, "web", slug, episodeSlug, headers));
    //             } else if (user_role) {
    //                 actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", episodeSlug, slug, headers));
    //             }
    //             return { status: true, data: actionResult }; // Return the action result as API response
    //         } catch (error) {
    //             console.error('Error fetching episode data:', error);
    //             return { status: false, error: error.message }; // Return error information if there's an error
    //         }
    //     } else {
    //         return { status: false, error: 'Invalid settings' }; // Return error if settings are invalid
    //     }
    // };
    const episodeApi = async () => {
        if (settings && Object.keys(settings).length > 0) {
            try {
                let actionResult;
                if (!user_role && settings.access_free == 1) {
                    actionResult = await dispatch(episodeDetailsAction(null, 9, "web", series_slug, episode_slug, headers));
                } else if (user_role) {
                    actionResult = await dispatch(episodeDetailsAction(user_id, user_role, "web", series_slug, episode_slug, headers));
                }
                //console.log(actionResult)
                return { status: true, data: actionResult }; // Return the action result as API response
            } catch (error) {
                console.error('Error fetching episode data:', error);
                return { status: false, error: error.message }; // Return error information if there's an error
            }
        } else {
            return { status: false, error: 'Invalid settings' }; // Return error if settings are invalid
        }
    };
    // console.log(slug)

    // const handlePlayButton = async () => {
    //     // if (episodeData && episodeData.series_season && episodeData.series_season.length > 0) {
    //     //     const firstSeason = episodeData.series_season[0];
    //     //     if (firstSeason.episodes && firstSeason.episodes.length > 0) {
    //     //         const episodeSlug = firstSeason.episodes[0].slug;
    //     try {
    //         const response = await episodeApi();
    //         if (response?.status && response?.data?.users_episode_visibility?.users_episode_visibility_status) {
    //             navigate(navigateTranslateChecker(`/episode/${series_slug}/${episode_slug}`));
    //         } else {
    //             alert(error)
    //             //navigate(navigateTranslateChecker(`/episode/${slug}/${episodeSlug}`));
    //         }
    //     } catch (error) {
    //         console.error('Error calling episodeApi:', error);
    //     }
    //     //     } else {
    //     //         console.error('No episodes found in the first season.');
    //     //     }
    //     // } else {
    //     //     console.error('No series season data found.');
    //     // }
    // };

    const htmlString = episodeData?.episode_description;
    const theObj = { __html: htmlString };
    const description = t(episodeData?.description);


    const formatDate1 = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
    };
    const date = episodeData?.year;
    const formattedDate = formatDate1(date);
    const [time, setTime] = useState('00:00:00');
    const [duration, setDuration] = useState('00:00:00')
    useEffect(() => {
        if (episodeData?.free_duration_time) {
            setTime(episodeData?.free_duration_time);
        }
        if (episodeData?.duration) {
            setDuration(episodeData?.duration)
        }
    }, [episodeDetailsData]);

    const convertTimeToSeconds = (time) => {
        const [hours, minutes, seconds] = time?.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };
    const totalSeconds = convertTimeToSeconds(time);
    const formatDuration = (duration) => {
        const parts = duration?.split(':');
        const hours = parts[0];
        const minutes = parts[1];
        const seconds = parts[2];
        return `${hours}H ${minutes}M ${seconds}S`;
    };


    //   geo ip location integration 


    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    const [convertedPrice, setConvertedPrice] = useState(null);

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = episodeData?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, episodeData?.ppv_price]);

    //   geo ip location integration 
    //   geo ip location integration 

    //    Iyzico payment integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = episodeData?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule GÃ¶ztepe, MerdivenkÃ¶y Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule GÃ¶ztepe, MerdivenkÃ¶y Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });


    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = episodeData?.ppv_price !== null ? episodeData?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = episodeData?.ContentPriceRate !== null
                    ? episodeData?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const successpage = window.location.href;
        const failerpage = `http://localhost:7000/` + lang + "/failerpage";
        const successpage = `http://localhost:7000/` + lang + "/successpage";
        const pageredirectionslug = `http://localhost:7000/` + lang + "/" + "series" + "/" + episodeData?.series_details?.slug + "/" + episodeData?.slug;
        const callbackUrl = `http://localhost:7004/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = episodeData?.title || episodeData?.name;
        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 4,
            source_id: episodeData?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            pageredirectionslug: pageredirectionslug,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                district: city,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));
    }, [episodeData, currency, settings, convertedPrice]);

    const initializeCheckoutIyzico = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };

    const initializeCheckoutStripe = async () => {

        const currentUrl = window.location.href; // Full URL of the current page
        const failureUrl = `${process.env.REACT_APP_Baseurl}/ppv/failerpage`;
        const currency = settingsall?.currency_settings?.currency_code;

        const formDatastripe = new FormData();
        formDatastripe.append("Login_device_type", "web");
        formDatastripe.append("unit_amount", episodeData?.ppv_price);
        formDatastripe.append("currency", currency);
        formDatastripe.append("source_type", 4);
        formDatastripe.append("source_id", episodeData?.id);
        formDatastripe.append("success_url", currentUrl);
        formDatastripe.append("failure_url", failureUrl);

        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/stripe-ppv-payment-link`, formDatastripe, { headers });
            if (response?.data?.status === true) {
                const paymentPageUrl = response?.data?.stripe_checkout_url;
                toast.success(`${response?.data?.message}`);
                window.location.href = paymentPageUrl; // Redirect
            } else if (response.data.status === "false") {
                toast.error(`${response.data.errorMessage}`);
            }
        } catch (error) {
            console.error("Error initializing checkout:", error);
            toast.error("Failed to initialize checkout. Please try again.");
        }
    };


    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 try {
    //                     const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
    //                     const country = locationResponse?.data?.country;
    //                     const country_code = locationResponse?.data?.country_code;
    //                     const city = locationResponse?.data?.city; // Assuming you need city here

    //                     if (city && country) { // Ensure both city and country are defined
    //                         showAlert = false;

    //                         const result = response.data;
    //                         const user_id = localStorage.getItem("user_id");
    //                         const web = "web";
    //                         const episodeDataid = episodeData?.id;

    //                         const formDataiyzico = new FormData();
    //                         formDataiyzico.append("Login_user_id", user_id);
    //                         formDataiyzico.append("Login_device_type", web);
    //                         formDataiyzico.append("source_type", 1);
    //                         formDataiyzico.append("source_id", episodeDataid);
    //                         // formDataiyzico.append("tokenid ", result.token);
    //                         formDataiyzico.append("payment_id", result.paymentId);
    //                         formDataiyzico.append("regionname", country);
    //                         formDataiyzico.append("countryname", country);
    //                         formDataiyzico.append("cityname", city);

    //                         console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // Log the FormData content

    //                         const postResponse = await axios.post(
    //                             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-ppv-verify-store`,
    //                             formDataiyzico
    //                         );

    //                         if (postResponse.data.status_code === 200) {
    //                             toast.success(`Purchased video was Created Successfully`);
    //                         } else {
    //                             toast.error(`Purchased video was Created Failed`);
    //                         }
    //                     } else {
    //                         console.error('City or country is undefined');
    //                     }
    //                 } catch (locationError) {
    //                     console.error('Error retrieving location data:', locationError);
    //                 }
    //             }
    //         } catch (paymentError) {
    //             console.error('Error retrieving payment details:', paymentError);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, [episodeData]); // Add `episodeData` as a dependency if it's used in the effect


    // Iyzico payment integration 
    // Iyzico payment integration 



    const [paymentGateways, setPaymentGateways] = useState([]);

    const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

    const fetchPaymentGateways = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
                { headers: headers }
            );
            const paymentGateways = response?.data?.Payment_Gateways;
            setPaymentGateways(paymentGateways);
            // Set the active gateway to the first one by default
            setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGatewayClick = (gatewayName) => {
        setActiveGateway(gatewayName);
    };


    useEffect(() => {
        // fetchOptions();
        fetchPaymentGateways();
    }, []);


    const staticContent = {
        Stripe: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckoutStripe()}>Stripe Pay to the {episodeData?.title ? episodeData.title : episodeData?.name}</button>
                </div>
            </>
        ),
        PayPal: (
            <>
                <div>
                    <button className='btn w-100' >PayPal Pay to the {episodeData?.title ? episodeData.title : episodeData?.name}</button>
                </div>
            </>
        ),
        Razorpay: (
            <>
                <div>
                    <button className='btn w-100'>Razorpay Pay to the {episodeData?.title ? episodeData.title : episodeData?.name}</button>
                </div>
            </>
        ),
        Iyzico: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckoutIyzico()}>Iyzico Pay to the {episodeData?.title ? episodeData.title : episodeData?.name}</button>
                </div>
            </>
        ),
    };

    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 showAlert = false;

    //                 const result = response.data;
    //                 const user_id = localStorage.getItem("user_id");
    //                 const episodeDataslug = episodeData?.slug;
    //                 const episodeDataid = episodeData?.id;

    //                 await axios.post('http://localhost:7001/payment/details', {
    //                     ...result,
    //                     user_id,
    //                     episodeDataslug,
    //                     episodeDataid,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Error retrieving payment details:', error);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, []); // Dependency array includes showAlert to re-run effect if showAlert changes


    //    Iyzico payment integration 
    //    Iyzico payment integration 

    //     <button className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2' onClick={() => initializeCheckoutIyzico()}>
    //     {currency?.code === null || currency?.code === ""
    //         ? `Rent Now ${episodeData?.ppv_price !== null ? episodeData?.ppv_price : 'Loading...'}`
    //         : (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0
    //             ? `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
    //             : (episodeData?.ContentPriceRate !== null
    //                 ? `Rent Now ${episodeData?.ContentPriceRate?.Symbol} ${episodeData?.ContentPriceRate?.ChangedPrice}`
    //                 : `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
    //             )
    //         )}  
    // </button>
    // console.log(seriesData?.access, "series-Access")
    // console.log(seasonData?.access, "season-Access")
    // console.log(episodeData?.access, "episode-Access")
    //console.log(settings?.enable_ppv_rent, "PPV Free for Subscriber")
    // console.log(episodePPVExists)
    // console.log((user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest"
    //     && episodePPVExists?.Episode_PPV_exists == false
    //     && episodePPVExists?.SeriesSeason_PPV_exists == false
    //     && episodePPVExists?.Series_PPV_exists == false
    // )
    // )
    const isGuestAccess = (data) => data?.access == "guest";
    const isRegisteredAccess = (data) => data?.access == "registered";
    const isSubscriberAccess = (data) => data?.access == "subscriber";
    const isPPVAccess = (data) => data?.access == "ppv";

    const hasPPV = (episodePPVExists) =>
        episodePPVExists?.Episode_PPV_exists ||
        episodePPVExists?.SeriesSeason_PPV_exists ||
        episodePPVExists?.Series_PPV_exists;


    const renderButtons = () => {


        const showWatchNowButton = (
            (!user_role && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "registered")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "registered")
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "registered")

            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")

            || (user_role == 3 && seriesData?.access == "subscriber" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 1)
            || (user_role == 1)
            || (user_role == 2 && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (user_role == 3 && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (user_role == 3 && seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 3 && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 3 && seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 1)
            || (user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 1)
            || (user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 1)
            || (user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 1)
            || (user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "guest" && settings?.enable_ppv_rent == 1)
            //|| (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 1)
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists)
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists)
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists)
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists ||
                user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 1)

            || (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" &&
                episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists)
            // For PPV_exists condition
            || (user_role == 2 && seriesData?.access == "ppv" && episodePPVExists?.Series_PPV_exists)
            || (user_role == 2 && episodePPVExists?.Episode_PPV_exists && episodePPVExists?.SeriesSeason_PPV_exists && episodePPVExists?.Series_PPV_exists)
            || (user_role == 3 && episodePPVExists?.Episode_PPV_exists && episodePPVExists?.SeriesSeason_PPV_exists && episodePPVExists?.Series_PPV_exists)
        );




        const showRegisterNowButton = (
            (!user_role && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "registered")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "registered")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "guest" && episodeData?.access == "registered")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "ppv")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "ppv" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv")
            || (seriesData?.access == "ppv" && !episodePPVExists?.Series_PPV_exists)
        );

        const showBecomeSubscriberButton = (
            (!user_role && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "guest")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "subscriber" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (!user_role && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "guest")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
            || (!user_role && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "ppv")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "subscriber" && seasonData?.access == "guest" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "ppv")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "ppv" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv")
            || (user_role == 2 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "guest")
            || (user_role == 2 && seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
            || (user_role == 2 && seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "ppv")
            || (user_role == 3 && seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 0)
        )


        // const showRentNowButton = (
        //     // User roles other than "1" can rent
        //     user_role !== "1" &&
        //     // Conditions for user role "2" where rent button should not appear
        //     !(
        //         user_role == 2 && (
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && episodePPVExists?.SeriesSeason_PPV_exists && episodePPVExists?.Series_PPV_exists) ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists) ||
        //             (seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists) ||
        //             (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists)
        //         )
        //     ) &&
        //     !(
        //         user_role == 3 && (
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "guest") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && episodePPVExists?.Episode_PPV_exists) ||
        //             (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "subscriber" && seasonData?.access == "registered" && episodeData?.access == "registered") ||
        //             (seriesData?.access == "subscriber" && seasonData?.access == "registered" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber") ||
        //             (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber") ||

        //             (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" &&
        //                 episodePPVExists?.Episode_PPV_exists && !episodePPVExists?.SeriesSeason_PPV_exists && !episodePPVExists?.Series_PPV_exists)
        //             //  (seriesData?.access == "guest" && seasonData?.access == "registered" && episodeData?.access == "subscriber")
        //         )
        //     ) &&
        //     // Cases where rent button should appear
        //     (
        //         (
        //             user_role == 2 && seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "ppv" &&
        //             !episodePPVExists?.Episode_PPV_exists && episodePPVExists?.SeriesSeason_PPV_exists && episodePPVExists?.Series_PPV_exists
        //         ) ||
        //         (
        //             user_role == 3 && seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "ppv" &&
        //             !episodePPVExists?.Episode_PPV_exists && episodePPVExists?.SeriesSeason_PPV_exists && episodePPVExists?.Series_PPV_exists
        //         ) ||
        //         (
        //             !user_role && (
        //                 (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "ppv" && episodeData?.access == "guest") ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber") ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "guest") ||
        //                 (seriesData?.access == "subscriber" && seasonData?.access == "subscriber" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "ppv")
        //             )
        //         ) ||
        //         (
        //             user_role == 2 && (
        //                 (seriesData?.access == "guest" && seasonData?.access == "ppv" && episodeData?.access == "guest") ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "guest") ||
        //                 (seriesData?.access == "registered" && seasonData?.access == "registered" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv") ||
        //                 (seriesData?.access == "ppv" && !episodePPVExists?.Series_PPV_exists) ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv"
        //                     && !episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists)

        //             )
        //         ) ||
        //         (
        //             user_role == 3 && (
        //                 (seriesData?.access == "ppv" && !episodePPVExists?.Series_PPV_exists)
        //                     (seriesData?.access == "guest" && seasonData?.access == "guest" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "ppv" && episodeData?.access == "guest" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "guest" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 0 &&
        //                     !episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "registered" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "ppv" && episodeData?.access == "subscriber" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "ppv" && seasonData?.access == "subscriber" && episodeData?.access == "guest" && settings?.enable_ppv_rent == 0) ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "ppv" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0 &&
        //                     !episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists) ||
        //                 (seriesData?.access == "guest" && seasonData?.access == "subscriber" && episodeData?.access == "ppv" && settings?.enable_ppv_rent == 0 &&
        //                     !episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists)
        //             )
        //         )
        //     )
        // );

        const showRentNowButton =
            user_role !== "1" && // User roles other than "1" can rent
            !(
                user_role == 2 &&
                (
                    (seriesData?.access === "guest" && seasonData?.access === "guest" && ["guest", "registered", "subscriber"].includes(episodeData?.access)) ||
                    (seriesData?.access === "guest" && seasonData?.access === "registered" && ["registered", "subscriber"].includes(episodeData?.access)) ||
                    (seriesData?.access === "guest" && seasonData?.access === "subscriber" && episodeData?.access === "subscriber") ||
                    (seriesData?.access === "ppv" && seasonData?.access === "ppv" && episodeData?.access === "ppv" &&
                        episodePPVExists?.Episode_PPV_exists &&
                        episodePPVExists?.SeriesSeason_PPV_exists &&
                        episodePPVExists?.Series_PPV_exists)
                )
            ) &&
            !(
                user_role == 3 &&
                (
                    (seriesData?.access === "guest" && seasonData?.access === "guest" && ["guest", "registered", "subscriber", "ppv"].includes(episodeData?.access)) ||
                    (seriesData?.access === "guest" && seasonData?.access === "registered" && episodeData?.access === "registered") ||
                    (seriesData?.access === "subscriber" && seasonData?.access === "registered" && ["registered", "subscriber"].includes(episodeData?.access)) ||
                    (seriesData?.access === "ppv" && seasonData?.access === "ppv" && episodeData?.access === "ppv" && episodePPVExists?.Episode_PPV_exists) ||
                    (seriesData?.access === "guest" && seasonData?.access === "subscriber" && episodeData?.access === "subscriber")
                )
            ) &&
            (
                (["2", "3"].includes(user_role) &&
                    seriesData?.access === "ppv" &&
                    seasonData?.access === "ppv" &&
                    episodeData?.access === "ppv" &&
                    !episodePPVExists?.Episode_PPV_exists &&
                    episodePPVExists?.SeriesSeason_PPV_exists &&
                    episodePPVExists?.Series_PPV_exists
                ) ||
                (
                    !user_role &&
                    (
                        (seriesData?.access === "guest" && seasonData?.access === "guest" && episodeData?.access === "ppv") ||
                        (seriesData?.access === "ppv" && ["guest", "subscriber"].includes(seasonData?.access) && ["guest", "ppv"].includes(episodeData?.access))
                    )
                ) ||
                (
                    user_role == 2 &&
                    (
                        (seriesData?.access === "ppv" && !episodePPVExists?.Series_PPV_exists) ||
                        (seriesData?.access === "guest" && seasonData?.access === "guest" && episodeData?.access === "ppv" &&
                            (!episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists))
                    )
                ) ||
                (
                    user_role == 3 &&
                    (
                        (seriesData?.access === "ppv" && !episodePPVExists?.Series_PPV_exists) ||
                        (seriesData?.access === "ppv" && !episodePPVExists?.Series_PPV_exists)
                        || !episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists
                            (settings?.enable_ppv_rent === 0 &&
                                (
                                    (seriesData?.access === "guest" && seasonData?.access === "ppv" && episodeData?.access === "ppv") ||
                                    (seriesData?.access === "ppv" && seasonData?.access === "subscriber" && ["ppv", "guest"].includes(episodeData?.access)) ||
                                    (seriesData?.access === "registered" && seasonData?.access === "subscriber" && episodeData?.access === "ppv")
                                ) &&
                                (!episodePPVExists?.Episode_PPV_exists || !episodePPVExists?.SeriesSeason_PPV_exists || !episodePPVExists?.Series_PPV_exists)
                            )
                    )
                )
            );


        return (
            <>
                {showWatchNowButton && (<button className="theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2"><Link className="theme-button-text-color" to={navigateTranslateChecker(`/episode/${series_slug}/${episode_slug}`)}><span>{t("Watch Now")}</span></Link></button>)}
                {showRegisterNowButton && (<button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className="theme-button-text-color" to={navigateTranslateChecker(`/signup`)}><span>{t("Register Now")}</span></Link></button>)}
                {showBecomeSubscriberButton && (<button className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"><Link className="theme-button-text-color" to={navigateTranslateChecker(`/${!user_role ? 'signup' : 'plan'}`)}><span>{t("Become a Subscriber")}</span> </Link></button>)}
                {showRentNowButton && (

                    <>
                        {currency?.enable_multi_currency === 1 || currency?.enable_multi_currency === "1" ? (
                            <button
                                className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            >
                                {convertedPrice !== null
                                    ? `Rent Now ${currencySymbol} ${convertedPrice.toFixed(2)}`
                                    : `Rent Now ${episodeData?.ContentPriceRate?.Symbol} ${parseFloat(episodeData?.ContentPriceRate?.ChangedPrice).toFixed(2)}`}
                            </button>
                        ) : (
                            <button
                                className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                            >
                                {t(`Rent Now ${currency?.symbol} ${parseFloat(episodeData?.ppv_price).toFixed(2)}`)}
                            </button>

                        )}
                    </>

                )}
            </>
        );
    };



    return (
        <>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}
            <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content theme-bg-color">
                        <div className="modal-header">
                            <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Upgrade to <span>{episodeData?.title ? episodeData.title : episodeData?.name}</span> video pack by just paying the difference </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body rent_video_page_ul_li">
                            <div className="row">
                                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                                <div className='row col-lg-12'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <img alt={episodeData?.title ? episodeData.title : episodeData?.name} src={episodeData?.Thumbnail} className='w-100 h-100' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                                        <div className='p-2'>
                                            <p className='fw-bold fs-5  theme-text-color'>BUY {episodeData?.title ? episodeData.title : episodeData?.name}</p>

                                            <hr></hr>

                                            <div className='mt-4'>
                                                <div>
                                                    <img alt={episodeData?.title ? episodeData.title : episodeData?.name} src={episodeData?.Player_thumbnail} className='w-50' />
                                                </div>
                                                <div className='mt-3'>
                                                    <ul>
                                                        <li className=' theme-text-color'>
                                                            Available to watch for <span className='fw-bold'>30 Days</span> after renting
                                                        </li>
                                                        <li className=' theme-text-color'>
                                                            Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className='mt-2'>
                                                <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                                                    {paymentGateways?.map((gateway, index) => (
                                                        <li
                                                            className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                                                            role="presentation"
                                                            key={index}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="nav-link"
                                                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                                name="tab-radio"
                                                                value={gateway?.Payment_Gateway_name}
                                                                checked={activeGateway == gateway?.Payment_Gateway_name}
                                                                onChange={() =>
                                                                    handleGatewayClick(gateway?.Payment_Gateway_name)
                                                                }
                                                            />
                                                            <label
                                                                className="ms-2 theme-text-color"
                                                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                            >
                                                                {gateway?.Payment_Gateway_name}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className='mt-2'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                                                        <div className="tab-content text-start" id="pills-tabContent">
                                                            {paymentGateways?.map((gateway, index) => (
                                                                <div
                                                                    className={`tab-pane theme-text-color ${activeGateway == gateway?.Payment_Gateway_name
                                                                        ? "show active"
                                                                        : "fade"
                                                                        }`}
                                                                    id={`pills-${gateway?.Payment_Gateway_name}`}
                                                                    role="tabpanel"
                                                                    key={index}
                                                                >
                                                                    <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}



            <div className='paddingTopone'>
                {error ? <Error404 /> : isLoading ? <PageLoader /> :
                    episodeDetailsData !== null ? <>

                        <HelmetDetails metaURL={currentPageURL} metaTitle={episodeData?.title ? episodeData?.title?.toUpperCase() : episodeData?.name?.toUpperCase()} metaDescription={episodeData?.description?.toString().substring(0, 100)}
                            metaImage={episodeData?.Player_thumbnail} pageTitle={episodeData?.title ? episodeData?.title?.toUpperCase() : episodeData?.name?.toUpperCase()} />
                        <div style={{ backgroundImage: `url(${episodeData?.Player_thumbnail})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: "fixed" }} className='px-md-5 px-3 py-md-3 py-3 linearShadow w-100 videoDetailsWrapper '>
                            <div className='position-relative'>
                                <Breadcrumb bread1={t("Home")} bread2={t("Episodes")} bread3={t(episodeData?.title)} link1="/" link2={navigateTranslateChecker(`/latest-series`)}
                                    slase />
                                <div className='pageWrapper2'>

                                    <div className="col-12 col-lg-10">
                                        <h3 className="midHeading2 mb-3 text-break">{t(episodeData?.title)} {t(episodeData?.name)} </h3>
                                        {episodeData?.episode_description ? <div dangerouslySetInnerHTML={theObj} className={`descript d-block text-wrap ${expanded ? 'h-auto' : episodeData?.episode_description.length > 200 ? 'hide' : ''}`}></div> : null}
                                        {episodeData?.episode_description?.length > 200 ? <button className='bg-transparent p-0' onClick={() => setExpanded(!expanded)}><MessageBox text={expanded ? 'Show Less' : 'Show More'} classname='theme-text-color descript mt-2 d-block' /></button> : null}

                                        {episodeData?.year || episodeData?.duration ?
                                            <div className='d-flex align-items-center flex-wrap my-4'>
                                                {episodeData?.year ? <label className='smallText1 beforeCircle text-break'>{t(formattedDate)}</label> : ""}
                                                {episodeData?.duration ? <label className='smallText1 ms-4 text-break '>{(formatDuration(duration))}</label> : ""}
                                            </div> : null}


                                        {episodeData?.language?.length > 0 ?
                                            <div className='d-flex align-items-center flex-wrap '>
                                                {episodeData?.language?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/languages/${item?.slug}`)} className={`position-relative smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3 me-4 beforeCircle`}>{t(item?.name)}</Link>
                                                ))}
                                            </div> : null}

                                        {episodeData?.genre?.length > 0 ?
                                            <div className='d-flex align-items-center flex-wrap '>
                                                {episodeData?.genre?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/series-categories/${item?.slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 text-break mb-3  me-4 beforeCircle `}>{t(item?.name)}</Link>
                                                ))}
                                            </div> : null}

                                        {episodeData?.artist?.length > 0 ?
                                            <div className='d-flex align-items-center  flex-wrap '>
                                                {episodeData?.artist?.map((item, index) => (
                                                    <Link to={navigateTranslateChecker(`/artists/${item.artist_slug}`)} className={`smallText1 theme-bg-color-secondary rounded-5 px-3 py-1 mb-3 text-break me-4 beforeCircle`}>{t(item?.artist_name)}</Link>
                                                ))}
                                            </div> : null}

                                        <div className="d-flex align-items-center gap-3 flex-wrap pt-3 position-relative">
                                            {renderButtons()}

                                            {/* {
                                            episodeData?.continue_watching_exists ?
                                                <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color' to={navigateTranslateChecker(`/episode/${series_slug}/${episode_slug}`)}><span>{t("Continue Watching")}</span></Link>
                                                : <Link className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2 theme-button-text-color' to={navigateTranslateChecker(`/episode/${series_slug}/${episode_slug}`)}><span>{t("Watch Now")}</span>
                                                </Link>
                                        } */}
                                            {/* <button className="add-btn d-flex align-items-center justify-content-between px-3 py-2 rounded-2 bg-transparent border theme-border-color " type="button" onClick={handleClickWishList1}>{episodeData?.wishlist ? <> {t("Remove To WishList")} <FiMinusCircle className="ms-3" style={{ "fontSize": "24px", "fontWeight": "bold" }} /></> : <>{t("Add To WishList")}<img className="ms-3" src={PlusImage} /> </>} </button> */}
                                            {/* <div className='shareContainer'>
                                                <FaShareAlt className="defaultIconStyle" />
                                                <SocialMediaShareBox URL={currentPageURL} />
                                            </div> */}
                                            {/* <FiThumbsUp className={`defaultIconStyle ${episodeData?.like == 1 ? "like-active" : ""}`}
                                                onClick={handleLikeClick1} />
                                            <FiThumbsDown className={`defaultIconStyle ${episodeData?.dislike == 1 ? "dislike-active" : ""}`}
                                                onClick={handleDislikeClick1} />
                                            {episodeData?.wishlist !== null ? <AiFillHeart className='watchlaterIconStyle' onClick={handleClickWishList1} /> : <AiOutlineHeart className='watchlaterIconStyle' onClick={handleClickWishList1} />}
                                            <div className='toolTipParent'>
                                                {episodeData?.WatchLater !== null ? <MdWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater1} /> : <MdOutlineWatchLater className='watchlaterIconStyle' onClick={handleClickWatchLater1} />}
                                                <ToolTip bgColor="#000" text={t("Watch Later")} top="-30px" right="-53px" className="px-3 py-2 rounded-2 position-absolute d-none " />
                                            </div> */}
                                        </div>

                                        {/* <div className="pt-4 d-flex flex-wrap gap-md-3 gap-1 justify-content-md-start">

                                        </div> */}
                                    </div>

                                    {/* <div className="col-12 col-lg-6 col-md-6 d-none d-md-block">
                                        <img src={episodeData?.Player_thumbnail} alt={episodeData?.slug} className="detailsSideImage lazyload d-block mx-auto w-100" loading="lazy" />
                                    </div> */}

                                    {/* <img src={outlineImage} className="w-100 pt-4 mt-3 pb-2" /> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='px-4 px-md-5'>
                        <div className="tab-container2 mt-2">
                            <div className="tabs2">
                                {tabs.map((tab, i) => (
                                    <div key={i} id={tab.id}
                                        className={currentTab === tab.id ? 'me-5 mt-2 tab2Text' : 'text-white me-5 mt-2 tab2Text'}
                                        disabled={currentTab === tab.id}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleTabClick(tab.id)}>
                                        {t(tab.title)}
                                        <div key={i} id={tab.id} className={currentTab === tab.id ? 'highlightTabColor' : ''} />
                                    </div>
                                ))}
                            </div>

                            <div className="content">
                                {tabs.map((tab, i) => (
                                    <div key={i}>
                                        {currentTab === tab.id && <div>{(tab.content)}</div>}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div> */}

                    </> :
                        <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3>{t("NO DATA FOUND")}</h3></div>
                }
            </div>

        </>
    )
}
const mapStateToProps = state => ({
    episodeDetailsData: state.get_episodeDetails_Reducer.episodeDetailsData,
    isLoading: state.get_episodeDetails_Reducer.isLoading,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    error: state.get_episodeDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    settingsall: state.get_setting_Reducer.settingsall,
});
export default connect(mapStateToProps)(Episodes)